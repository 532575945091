import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import Button from "../../components/atom/button/Button";

import NewsItem from "../../components/element/news-item/NewsItem";
import news from "../../assets/lotties/news/anim--icon-news";

import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";

import { getAllNews, readNews } from "../../data/api";


import { formatDate } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";
import ScrollToTop from "../../components/basics/ScrollToTop/ScrollToTop";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      loading: true
    };
  }

  onReadAction = async (id) => {
    await readNews(id)
    await this.loadData()
  }

  loadData = async () => {
    const news = await getAllNews()

    this.setState({
      news: news.news,
      loading: false,
    });
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
        this.setState({
          ...this.state,
          newsId: parseInt(this.props.location.hash.substring(1)),
        }, async () => {
          await this.loadData()
        })
    }
  }

  render() {
    if (this.state.loading) {
      return "Loading..";
    }

    return (
      <>
        <div className="content news">
          <span className="news__header">
            <UncontrolledLottie animationData={news} />
          </span>
          <div className="news-content-detail">
            <ScrollToTop />
            <ul>
              {this.state.news.length > 0 ? (
                this.state.news.map((single) => (
                  <li key={single.id} id={single.id}>
                    <NewsItem
                      heading={single.headline}
                      title={single.title}
                      id={single.id}
                      date={single.valid_from ? formatDate(single.valid_from, false) : ""}
                      isRead={single.was_read}
                      text={single.body}
                      subheadline={single.subheadline}
                      onRead={this.onReadAction}
                      teaserimage={single.teaserimage_id}
                      newsOpen={(this.state.newsId === single.id)}
                    />
                  </li>
                ))
              ) : (
                <>
                  <div className={`orders__left orders__left--empty`}>
                    <div className={"orders__empty news__empty"}>
                      <h2>{t("app.news.no.heading")}</h2>

                      {this.context.getHasShopAccess() && (
                        <span className={"orders__empty-actions"}>
                          <Link to={`/${window.currentLang}/shop`}>
                            <Button
                              type={"primary"}
                              textColor={"white"}
                              text={t("app.news.no.button")}
                            />
                          </Link>
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

News.contextType = AppContext;
export default withRouter(News);
