import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { throttle } from "throttle-debounce";

import Icon from "../../atom/icons/Icon";
import Image from "../../atom/image/Image";
import Button from "../../atom/button/Button";

import Textfield from "../../atom/textfield/Textfield";


import { AppContext } from "../../../data/ContextProvider";

import { getToken, getUserAvatar, logoutUser } from "../../../lib/lib";
import { getLatestNews, getProducts } from "../../../data/api";
import t from "../../../helpers/t9n";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import { renderDescription, renderGroup, renderTitle } from "../../../helpers/utils";

class MainMenu extends Component {

  constructor(props, context) {
    super(props);
    this.state = {
      open: props.isOpen,
      stats: {},
      search_term: "",
      loading: true,
      news: []
    }
    this.searchThrottled = throttle(800, this.searchForProducts);
  }

  getNews = async () => {
    const news = await getLatestNews();

    this.setState({
      news: news.news,
    });
  }

  filterNews = async () => {
    await this.getNews();
    const news = this.state.news
    const unreadNews = []

    if (news.length) {
      news.forEach(item => {
        unreadNews.push(item.was_read)
      })
    }

    const filteredNews = unreadNews.filter(unreadNews => unreadNews !== true);

    this.setState({
      filteredNews: filteredNews
    })
  }

  async componentDidMount() {
    await this.filterNews();

    this.setState({
      stats: this.context.getStatsOfUser(),
      loading: false
    })

  }

  handleMainMenuLink = (path) => {
    this.context.handleMainMenu();
    this.props.history.push(`/${window.currentLang}/` + path)
  }

  handleSearchChange = (e) => {
    const val = e.target.value

    if (!this.context.isSearchOpen)
      this.context.handleSearch()

    this.setState({
      search_term: val,
    }, () => {
      this.searchForProducts()
    })
  }

  searchForProducts = async () => {
    if (this.state.search_term.length > 2) {
      const products = await getProducts(getToken(), 0, this.state.search_term)
      this.setState({ products: products.products })
    }
  }

  resetSearch = () => {
    this.setState({
      search_term: "",
      products: null
    })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const avatar = getUserAvatar()
    const noShopAccess = this.props.noShopAccess
    const noRules = this.props.noRules
    const hasWertung = this.props.hasWertung
    const hasRanking = this.props.hasRanking

    const hasPerformancedataPage = this.props.hasPerformancedataPage
    const hasContractsPage = this.props.hasContractsPage

    const stats = this.state.stats

    const hasOpenContracts = this.context.hasOpenContracts()

    return (
      <>
        <div
          className={`main-menu__wrapper
          ${this.context.isMainMenuOpen ? "main-menu__wrapper--open" : ""}
          ${this.context.isSearchOpen ? "main-menu__wrapper--search" : ""}
          `}>

            <div className={"main-menu__actions--mobile"}>
              <span className={"main-menu__close"} onClick={() => this.context.handleMainMenu()}>
                <Icon icon={"arrow"} />
              </span>
              <Textfield
                placeholder={t("menu.search")}
                inputType="text"
                name="search_term"
                value={this.state.search_term}
                handleChange={this.handleSearchChange}
                isControlled={true}
              />
              <span className={"main-menu__search-icon"} onClick={this.resetSearch}>
                <Icon icon={`${this.state.search_term.length > 0 ? "close" : "search"}`} />
              </span>
            </div>

          <div className={"main-menu__container"}>
            <span className={"main-menu__close"} onClick={() => this.context.handleMainMenu()}>
              <Icon icon={"close"} />
            </span>
              <div
                className={`main-menu__product-search ${this.context.isSearchOpen ? "main-menu__product-search--active" : ""}`}>

                <div className={"main-menu__product-search-header"}>
                  <h2>{t("menu.search")}</h2>
                  <span>
                    <Textfield
                      placeholder={t("menu.search")}
                      inputType="text"
                      name="search_term"
                      value={this.state.search_term}
                      handleChange={this.handleSearchChange}
                      isControlled={true}
                    />
                    <span className={"main-menu__search-icon"} onClick={this.resetSearch}>
                      <Icon icon={`${this.state.search_term.length > 0 ? "close" : "search"}`} />
                    </span>
                  </span>
                </div>

                {this.state.products && this.state.products.length >= 0 && this.state.products.map((prod, i) => (
                  <div className={"main-menu__search-result"} onClick={() => {
                    this.context.handleMainMenu()
                    this.props.history.push(`/${window.currentLang}/product/${prod.id}`)
                  }} key={prod.id + "_" + i}>
                    <Image image={prod.images[0].url} />
                    <div className={"main-menu__search-result-content"}>
                      <p>{renderGroup(prod)}</p>
                      <h3>{renderTitle(prod)}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: renderDescription(prod)
                        }}>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
                <div className={"main-menu__profile"}>
                  <span className={"main-menu__image"}>
                    <Image type={"fullwidth"} alt={"Profile Image"}
                           image={avatar ? `${process.env.REACT_APP_API_HOST_LOGIN}` + avatar : `${process.env.PUBLIC_URL}/images/img--user-placeholder.jpg`} />
                  </span>
                  <span className={"main-menu__profile-content"}>
                    <span className={"main-menu__greeting"}>
                    {t("menu.welcome.text")}

                    {/* Language Switcher */}
                    <LanguageSwitcher menu={true} />

                    </span>
                    <span className={"main-menu__username"}>
                      {this.context.getNameOfUser()}
                    </span>
                    <a href={`/${window.currentLang}/profile`}>{t("menu.edit.profile")}</a>
                  </span>
                </div>

                <div className={`main-menu`}>
                  <ul>
                    <li>
                      <Button icon={"home"}
                              text={t("menu.home")}
                              type={"secondary"}
                              isClubButton={this.props.isClub}
                              isGold={this.props.isClub}
                              onClick={() => this.handleMainMenuLink("")} />
                    </li>
                    {(!noShopAccess && hasWertung) &&
                    <li>
                        <Button icon={"star"}
                                text={t("menu.ratings")}
                                type={"secondary"}
                                isClubButton={this.props.isClub}
                                isGold={this.props.isClub}
                                onClick={() => this.handleMainMenuLink("wertungskriterien")} />
                      </li>
                    }
                    {(!noShopAccess && hasRanking) &&
                    <li>
                        <Button icon={"ranking"}
                                text={t("menu.ranking")}
                                type={"secondary"}
                                isClubButton={this.props.isClub}
                                isGold={this.props.isClub}
                                onClick={() => this.handleMainMenuLink("ranking")} />
                      </li>
                    }

                    {noShopAccess ?
                      <>
                        {hasContractsPage &&
                        <li>
                            <Button icon={"vertragszuordnung"}
                                    text={t("menu.sort.contracts")}
                                    type={"secondary"}
                                    isClubButton={this.props.isClub}
                                    isGold={this.props.isClub}
                                    onClick={() => this.handleMainMenuLink("contracts/new")} />
                          </li>
                        }

                        {hasPerformancedataPage &&
                        <li>
                            <Button icon={"single-neutral-actions"}
                                    text={t("menu.my.contracts")}
                                    type={"secondary"}
                                    isClubButton={this.props.isClub}
                                    isGold={this.props.isClub}
                                    onClick={() => this.handleMainMenuLink("performancedata")} />
                          </li>
                        }
                        <li>
                          <Button icon={"ranking"}
                                  text={t("menu.ranking")}
                                  type={"secondary"}
                                  isClubButton={this.props.isClub}
                                  isGold={this.props.isClub}
                                  onClick={() => this.handleMainMenuLink("ranking")} />
                        </li>
                        <li>
                          <Button icon={"star"}
                                  text={t("menu.ratings")}
                                  type={"secondary"}
                                  isClubButton={this.props.isClub}
                                  isGold={this.props.isClub}
                                  onClick={() => this.handleMainMenuLink("wertungskriterien")} />
                        </li>
                      </>
                      :
                      <>
                        <li>
                          <Button icon={"single-neutral-actions"}
                                  text={t("menu.points.account")}
                                  type={"secondary"}
                                  isClubButton={this.props.isClub}
                                  isGold={this.props.isClub}
                                  onClick={() => this.handleMainMenuLink("account")} />
                        </li>
                        {hasContractsPage &&
                        <li>
                            {hasOpenContracts ? <span className="notification">!</span> : null}
                            <Button icon={"vertragszuordnung"}
                                    text={t("menu.sort.contracts")}
                                    type={"secondary"}
                                    isClubButton={this.props.isClub}
                                    isGold={this.props.isClub}
                                    onClick={() => this.handleMainMenuLink("contracts/new")} />
                          </li>
                        }
                        {hasPerformancedataPage &&
                        <li>
                            <Button icon={"single-neutral-actions"}
                                    text={t("menu.my.contracts")}
                                    type={"secondary"}
                                    isClubButton={this.props.isClub}
                                    isGold={this.props.isClub}
                                    onClick={() => this.handleMainMenuLink("performancedata")} />
                          </li>
                        }
                        <li>
                          {stats.cart > 0 &&
                          <span className={"notification"}>{stats.cart}</span>
                          }
                          <Button icon={"shopping-cart-full"}
                                  text={t("menu.shopping.cart")}
                                  type={"secondary"}
                                  isClubButton={this.props.isClub}
                                  isGold={this.props.isClub}
                                  onClick={() => this.handleMainMenuLink("cart")} />
                        </li>
                        <li>
                          {stats.order > 0 &&
                          <span className={"notification"}>{stats.order}</span>
                          }
                          <Button icon={"check-list"}
                                  text={t("menu.order.status")}
                                  type={"secondary"}
                                  isClubButton={this.props.isClub}
                                  isGold={this.props.isClub}
                                  onClick={() => this.handleMainMenuLink("orders")} />
                        </li>
                        <li>
                          {stats.wishlist > 0 &&
                          <span className={"notification"}>{stats.wishlist}</span>
                          }
                          <Button icon={"heart"}
                                  text={t("menu.wishlist")}
                                  type={"secondary"}
                                  isClubButton={this.props.isClub}
                                  isGold={this.props.isClub}
                                  onClick={() => this.handleMainMenuLink("wishlist")} />
                        </li>
                      </>
                    }
                    <li>
                      {this.state.filteredNews.length > 0 &&
                      <span className={"notification"}>{this.state.filteredNews.length}</span>
                      }
                      <Button icon={"new-message"}
                              text={t("menu.news")}
                              type={"secondary"}
                              isClubButton={this.props.isClub}
                              isGold={this.props.isClub}
                              onClick={() => this.handleMainMenuLink("news")} />
                    </li>
                    {!noShopAccess &&
                    <li>
                      <Button icon={"star"}
                              text={t("menu.award.shop")}
                              type={"secondary"}
                              isClubButton={this.props.isClub}
                              isGold={this.props.isClub}
                              onClick={() => {
                                this.context.handleMainMenu();
                                this.props.history.push(`/${window.currentLang}/shop`);
                              }} />
                    </li>
                    }
                    {!noRules &&
                      <li>
                        <Button icon={"gamerules"} text={t("global.rules")} type={"secondary"}
                          isClubButton={this.props.isClub}
                          isGold={this.props.isClub}
                          onClick={() => {
                            this.context.handleMainMenu();
                            this.props.history.push(`/${window.currentLang}/rules`);
                          }} />
                      </li>
                }
                  </ul>
                </div>

                <div className={"main-menu__link-list"}>
                  <ul>
                    {noShopAccess ?
                      <li>
                        <a href={`/${window.currentLang}/tos`}>{t("menu.conditions.participation")}</a>
                      </li>
                      :
                      <>
                        <li>
                          <a href={`/${window.currentLang}/shipping`}>{t("menu.terms.delivery")}</a>
                        </li>
                      </>
                    }
                    <li>
                      <a href={`/${window.currentLang}/contact`}>{t("menu.contact")}</a>
                    </li>
                    <li>
                      <a onClick={() => {
                        logoutUser()
                      }} href={`/${window.currentLang}/login`}>{t("menu.logout")}</a>
                    </li>
                  </ul>
                </div>

          </div>
        </div>
        <div className={`main-menu__overlay ${this.context.isMainMenuOpen ? "main-menu__overlay--visible" : ""}`}>
        </div>
      </>
    );
  }
}

MainMenu.contextType = AppContext;

MainMenu.defaultProps = {
  isOpen: true
};

export default withRouter(MainMenu);
