import React, { Component } from "react"

import Button from "../../atom/button/Button"

import { AppContext } from "../../../data/ContextProvider"
import { getAppPrefs, getRankingLevels, getUserRankingForHerosplace, getTransactionsForHerosPlace, getAppTexts } from "../../../data/api"
import { APP_HEROSPLACE } from "../../../data/constants"

import HerosPlaceBanner from "./HerosPlaceBanner"
import HerosPlaceRanking from "./HerosPlaceRanking"
import HerosPlacePerformance from "./HerosPlacePerformance"

import t from "../../../helpers/t9n"

export class HerosPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null
    };
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs(APP_HEROSPLACE)
    const texts    = await getAppTexts(APP_HEROSPLACE)

    const rankinglevels = await getRankingLevels(APP_HEROSPLACE)
    const rankings = await getUserRankingForHerosplace()
    const transactions = await getTransactionsForHerosPlace()

    this.setState({
      transactions: transactions.transactions,
      appPrefs: appPrefs,
      rankingLevels: rankinglevels.rankinglevels,
      rankings: rankings,
      pageText: texts,
      loading: false
    })
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <HerosPlaceBanner
              text={this.state.pageText}
              headline={this.state.appPrefs.name}
              appID={APP_HEROSPLACE}
            />

            <HerosPlacePerformance
              transactions={this.state.transactions}
              currencyMode={this.state.appPrefs.currency_mode}
              start={this.state.appPrefs.start}
              end={this.state.appPrefs.end}
            />

            <HerosPlaceRanking
              user_transactions={this.state.transactions.user_transactions}
              ranking_levels={this.state.rankingLevels}
              currencyMode={this.state.appPrefs.currency_mode}
              ranking={this.state.rankings.ranking}
              user_rank={this.state.rankings.user_rank}
              heroTitle={this.state.appPrefs.hero_bonus_title}
              heroDescription={this.state.appPrefs.hero_bonus_desc}
              heroBonus={this.state.appPrefs.hero_bonus}
            />
          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_HEROSPLACE)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_HEROSPLACE)}
            />
          )}
        </div>
      </>
    );
  }
}

HerosPlace.contextType = AppContext;
export default HerosPlace;
