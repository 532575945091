import React, { Component } from "react";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import Image from "../../atom/image/Image";

export class HerosPlaceRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isRankingsOpen: false,
      scrollTop: 0,
    };

    this.myRef = React.createRef();
  }

  onScroll = () => {
    const scrollTop = this.myRef.current.scrollTop;
    this.setState({
      scrollTop: scrollTop,
    });
  };

  renderOwnPerformance() {
    const title =
      this.props.user_rank && this.props.user_rank.level_title
        ? this.props.user_rank.level_title
        : "RANKING";

    return (
      <>
        {this.props.user_rank.ranking_level ? (
          <div className={"heros-ranking__stats"}>
            <div className={"heros-ranking__stats--left"}>
              <div className={"heros-ranking__rank--wrapper"}>
                <span className={"heros-ranking__rank"}>
                  <h1>{this.props.user_rank.place}</h1>
                </span>
                <span className={"heros-ranking__rank--name"}>
                  <h3>{title}</h3>
                </span>
                <span className={"heros-ranking__rank--label"}>
                  <p>{t("app.your.place")}</p>
                </span>
              </div>

              <div className={"heros-ranking__current"}>
                <h5>{t("app.current.level")}</h5>
                <span className={"heros-ranking__points-container"}>
                  <h3>
                    {Number(
                      this.props.user_rank.ranking_level
                    ).toLocaleString()}
                  </h3>
                  <h4>{t("app.points")}</h4>
                </span>
              </div>
            </div>
            <div className={"heros-ranking__stats--right"}>
              <div className={"heros-ranking__avatar"}>
                <Image
                  image={
                    process.env.PUBLIC_URL +
                    "/images/app/heros-place/img--avatar.png"
                  }
                  alt={"avatar"}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {!this.props.user_rank.ranking_level && this.props.user_rank.above_average ? (
          <div className={"heros-ranking__stats"}>
            <div className={"heros-ranking__stats--left"}>
              <div className={"heros-ranking__rank--wrapper"}>
                <span className={"heros-ranking__rank"}>
                  <h1>{this.props.user_rank.place}</h1>
                </span>
                <span className={"heros-ranking__rank--name"}>
                  <h3>{title}</h3>
                </span>
                <span className={"heros-ranking__rank--label"}>
                  <p>{t("app.your.place")}</p>
                </span>
              </div>

              <div className={"heros-ranking__current"}>
                <h5>{t("app.current.level")}</h5>
                <span className={"heros-ranking__points-container"}>
                  <h3>
                    {Number(
                      this.props.user_rank.above_average_points
                    ).toLocaleString()}
                  </h3>
                  <h4>{t("app.points")}</h4>
                </span>
              </div>
            </div>
            <div className={"heros-ranking__stats--right"}>
              <div className={"heros-ranking__avatar"}>
                <Image
                  image={
                    process.env.PUBLIC_URL +
                    "/images/app/heros-place/img--avatar.png"
                  }
                  alt={"avatar"}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="heros-ranking__board">
          <h3>{t("app.win.level")}</h3>
          <div
            className={
              this.state.scrollTop !== 0
                ? "heros-ranking__board--wrapper heros-ranking__board--wrapper--scroll"
                : "heros-ranking__board--wrapper"
            }
            ref={this.myRef}
            onScroll={this.onScroll}
          >
            {this.props.ranking_levels.map((rank, i) => {
              return (
                <div className="heros-ranking__row" key={rank.title + "_" + i}>
                  <div className="heros-ranking__item">
                    <Icon icon={"ranking-neutral"} />
                    <span>
                      <h3>{rank.title}</h3>
                      <h3>{rank.description}</h3>
                    </span>
                    {/*<p>Platz {Number(rank.from)} - {Number(rank.to)}</p>*/}
                    {rank.to ? (
                      <p>
                        {t("app.place")}{" "}
                        {Number(rank.from) === Number(rank.to)
                          ? `${Number(rank.from)}`
                          : `${Number(rank.from)} - ${Number(rank.to)}`}
                      </p>
                    ) : (
                      <p>
                        {t("app.place")} {Number(rank.from)}
                      </p>
                    )}
                  </div>
                  <div className="heros-ranking__score">
                    <span className={"heros-ranking__points-container"}>
                      <h3>{Number(rank.amount).toLocaleString()}</h3>
                      <h4>{t("app.points")}</h4>
                    </span>
                  </div>
                </div>
              );
            })}

            <div className="heros-ranking__row">
              <div className="heros-ranking__item">
                <Icon icon="confetti" />
                <span>
                  <h3>{this.props.heroTitle}</h3>
                  <h3>{this.props.heroDescription}</h3>
                </span>
              </div>
              <div className="heros-ranking__score">
                <span className={"heros-ranking__points-container"}>
                  <h3>{Number(this.props.heroBonus).toLocaleString()}</h3>
                  <h4>{t("app.points")}</h4>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderRankingPlaces() {
    return (
      <React.Fragment>
        <div className={"heros-ranking__board"}>
          <h3>{t("app.overall.ranking")}</h3>
          <div
            className={
              this.state.scrollTop !== 0
                ? "heros-ranking__board--wrapper heros-ranking__board--wrapper--scroll"
                : "heros-ranking__board--wrapper"
            }
            ref={this.myRef}
            onScroll={this.onScroll}
          >
            {this.props.ranking.map((rank, index) => {
              return (
                <div key={index} className="heros-ranking__row">
                  <div className="heros-ranking__item heros-ranking__item--all">
                    <Icon icon={"ranking-neutral"} />
                    <span>
                      <h3>{rank.user_name}</h3>
                    </span>
                    <p>
                      {t("app.place")} {Number(rank.place)}
                    </p>
                  </div>
                  <div className="heros-ranking__score">
                    <span className={"heros-ranking__points-container"}>
                      <h3>{Number(rank.amount).toLocaleString()}</h3>
                      <h4>
                        {this.props.currencyMode === "currency" ? (
                          <p>{t("app.sales.contract")}</p>
                        ) : (
                          <p>{t("app.contracts.closed")}</p>
                        )}
                      </h4>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div
        className={`heros-ranking__section ${
          this.state.isOpen ? "heros-ranking__section--open" : ""
        }`}
      >
        <span className="heros-ranking__header">
          <h2 className="heros-ranking__label">
            {t("app.herosplace.ranking")}
          </h2>
        </span>
        <div className={"heros-ranking__content"}>
          <div className={"heros-ranking__wrapper"}>
            {!this.state.isRankingsOpen
              ? this.renderOwnPerformance()
              : this.renderRankingPlaces()}
          </div>
          <span className={"heros-ranking__actions"}>
            <Button
              type={"secondary"}
              text={`${
                this.state.isRankingsOpen
                  ? t("app.back.win.level")
                  : t("app.to.overall.ranking")
              }`}
              textColor={"white"}
              onClick={() =>
                this.setState({ isRankingsOpen: !this.state.isRankingsOpen })
              }
            />
          </span>
        </div>
      </div>
    );
  }
}

export default HerosPlaceRanking;
