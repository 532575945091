import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";
import Button from "../../atom/button/Button";
import Icon from "../../atom/icons/Icon";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import jackpot from "../../../assets/lotties/jackpot/anim--jackpot-gift";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";

import { bodyForLang } from "../../../lib/lib";

export class PraemienjackpotBanner extends Component {
  render() {
    const texts = this.props.text

    let subline = ""
    let body    = ""
    let headline    = ""

    texts.forEach(text => {
      if (text.key === 'p33_header_content_headline') {
        headline = text
      }
      if (text.key === 'p33_header_content_subline') {
        subline = text
      }
      if (text.key === 'p33_upper_content_text') {
        body = text
      }
    })

    return (
      <>
        <div className="app-header__section praemienjackpot-header__section app-header__section--open">
          <video loop muted autoPlay playsInline className="oldie-hide">
            <source
              src={`${process.env.PUBLIC_URL}/videos/Jackpot/anim--jackpot-bg.mp4`}
              type="video/mp4"
            />
            {t("app.video.no.support")}
          </video>
          <div className="app-header__content">
            <div className="app-header__left">
              <span className="app-header__heading">
                <div>
                  <span>
                    <Icon icon="app-praemienjackpot" />
                  </span>
                  <h1>{ bodyForLang(headline, window.currentLang)}</h1>
                </div>
                <h2>{ bodyForLang(subline, window.currentLang)}</h2>
              </span>
              <p dangerouslySetInnerHTML={{ __html: bodyForLang(body, window.currentLang) }}></p>
              <span className="app-header__actions">
                <Button
                  type="secondary"
                  text={t("global.rules")}
                  textColor="black"
                  backgroundColor="bg-white"
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/rules/${this.props.appID}`);
                  }}
                />
              </span>
            </div>
            <div className="app-header__right">
              <div className="jackpot-counter">
                <span className="jackpot-counter__icon">
                  <UncontrolledLottie animationData={confetti} />
                </span>
                <h2>{t("app.jackpot.counter")}</h2>
                <span className={"jackpot-counter__wrapper"}>
                  <CounterSpawner
                    amount={~~Number(this.props.jackpot)}
                    type={"highlight"}
                  />
                  <h5>{t("app.points")}</h5>
                </span>

                <h5>{t("app.still.in.jackpot")}</h5>
                <div className="jackpot-counter__bank">
                  <h5>{t("app.your.point.status")}</h5>
                  <h2>
                    <b>
                      {Number(this.context.getPointsOfUser()).toLocaleString()}{" "}
                      {t("app.points")}
                    </b>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <span className="app-header__box">
            <UncontrolledLottie animationData={jackpot} />
          </span>
        </div>
      </>
    );
  }
}

PraemienjackpotBanner.contextType = AppContext;
export default withRouter(PraemienjackpotBanner);
