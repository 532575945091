const t9n = {

  // ===== DE =====

  "de": {
    // Login
    "login.h1": "Herzlich Willkommen",
    "login.h2.subline": "IN IHREM KUNDENPORTAL",
    "login.h2.login": "Anmeldung",
    "login.label.username": "E-Mail *",
    "login.input.username": "E-Mail",
    "login.label.password": "Passwort",
    "login.input.password": "Passwort",
    "login.button": "Login",
    "login.forgotten.password": "Haben Sie ihr Passwort vergessen?",
    "login.error.info": "Es ist etwas schief gelaufen. Bitte Überprüfen Sie Ihre eingegebene Daten oder wenden Sie sich an unseren Support.",
    "login.new.password": "Legen Sie ein Neues fest.",
    "login.contact.support": "Sie können sich nicht einloggen? Überprüfen Sie Ihre Zugangsdaten, die Sie von uns per Mail erhalten haben.",
    "login.service.mail": "service@salesattack.de",
    "login.data.protection": "Datenschutz",
    "login.imprint": "Impressum",
    "login.contact": "Kontakt",
    "login.password_policy": "Bitte vergeben Sie ein neues Passwort. Dieses muss mindestens 8 Zeichen, Groß- und Kleinschreibung und mindestens eine Zahl beinhalten.",
    "login.error.mail": "Die E-Mail Adresse darf nicht leer sein",
    "login.error.password_policy": "Ihr gewähltes Passwort entspricht nicht den Sicherheitsbestimmungen. Ihr Passwort muss aus mindestens 8 Zeichen bestehen und Groß- und Kleinschreibung und mindestens eine Zahl beinhalten.",
    "login.error.password_mismatch":"Passwörter stimmen nicht überein!",
    "login.error.password_is_empty":"Das Passwort darf nicht leer sein",
    "login.password_reset.headline":"Passwort zurücksetzen",
    "login.password_reset.description": "Hier haben Sie die Möglichkeit Ihr Passwort zurückzusetzen. Sobald Sie ihre E-Mail Adresse bestätigen, werden Sie aufgefordert ein neues Passwort zu vergeben.",
    "login.password_reset.email_success": "Wir haben Ihnen einen Link zur Passwortänderung an Ihre E-Mail-Adresse geschickt.",
    "login.password_reset.success": "Super, das hat geklappt! Das Passwort wurde erfolgreich zurückgesetzt.",
    "login.userdata.headline": "Datenvervollständigung",
    "login.tos.headline": "Teilnahmebedingungen",
    "login.credentials.error": "Die E-Mail-Adresse oder das Passwort ist falsch.",

    // Global
    "global.close.info": "Infos schließen",
    "global.mandatory.fields": "* Pflichtfelder",
    "global.open.info": "Weitere Infos",
    "global.rules": "Die Spielregeln",
    "global.rules.short": "Spielregeln",
    "global.points.account": "Mein Punktekonto",
    "global.award.overview": "Prämienübersicht",
    "global.recommendation": "Unsere Empfehlungen",
    "global.loading": "Lädt...",
    "global.your.cart": "Ihr Warenkorb",
    "global.your.order": "Ihre Bestellung",
    "global.less.info": "Weniger Infos",
    "global.back_to_login": "Zurück zum Login",
    "global.next": "Weiter",
    "global.account.heading": "Punktestand",
    "global.account.source": "Quelle",
    "global.account.date": "Datum",
    "global.account.desc": "Beschreibung",
    "global.account.points": "Punkte",
    "global.account.sum": "Summe im Zeitraum",
    "global.account.old.points": "Aktueller Kontostand",
    "global.empty.table": "Keine Einträge vorhanden",
    "globale.change.products": "Zu den Prämien wechseln",
    "global.cart.empty": "Ihr Warenkorb ist leer.",
    "global.wishlist.button": "Meine Wunschliste anzeigen",
    "global.button.go.to.contact": "Zum Kontaktformular",
    "global.badge.neuheit": "Neuheit",
    "global.badge.highlight": "Highlight",
    "global.badge.luxus": "Luxusprämie",
    "global.key.new": "NEU",
    "global.order.view": "Bestellübersicht",
    "global.order.button.view": "Zur Bestellübersicht",
    "global.back.to.homepage": "Zurück zur Startseite",
    "global.something.went.wrong": "Ups, es ist etwas schief gelaufen.",
    "global.confirmation.heading": "Vielen Dank, wir haben Ihre Bestellung erhalten.",
    "global.confirmation.text": "Sie erhalten in den nächsten Minuten eine Bestellbestätigung an die angegebene E-Mail-Adresse. Bei Fragen zu Ihrer Bestellung prüfen Sie bitte Ihren Bestellstatus unter „Konto / Bestellstatus“. Dort können Sie jederzeit den aktuellen Versand-Status Ihrer Bestellung einsehen. Wir wünschen Ihnen die Spaß mit Ihrer Prämie.",
    "global.no.orders.in.time": "Keine Bestellungen im Zeitraum vorhanden.",
    "global.no.orders": "Keine Bestellungen vorhanden.",
    "global.switch.to.products": "Zu den Prämien wechseln",
    "global.accordion.orders.heading": "Zeitraum-Filter",
    "global.flatpicker.start": "von",
    "global.flatpicker.end": "bis",
    "global.flatpicker.date": "Datum wählen..",
    "global.flatpicker.button": "Neu laden",
    "global.wishlist.page.hint": "Mit Hilfe Ihrer Wunschliste können Sie Prämeinwünsche dauerhaft speichern. Setzen Sie dazu die Prämie auf Ihre Wunschliste. Sobald Sie über ausreichend Punkte zur Bestellung verfügen, werden Sie automatisch per E-Mail informiert und können die Artikel der Wunschliste ganz bequem in eine Bestellung übernehmen.",
    "global.wishlist.page.remove.all": "Alle Artikel entfernen",
    "global.wishlist.page.add.all": "Alle Artikel in den Warenkorb",
    "global.wishlist.page.empty": "Ihre Wunschliste ist leer.",
    "global.wishlist.page.button.cart": "Zum Warenkorb",
    "global.profile.page.heading": "Ihr Profil",
    "global.homepage.start": "Los geht´s",
    "global.countries.germany": "Deutschland",
    "global.countries.belgium": "Belgien",
    "global.countries.denmark": "Dänemark",
    "global.countries.finnland": "Finnland",
    "global.countries.france": "Frankreich",
    "global.countries.uk": "Großbritannien",
    "global.countries.italy": "Italien",
    "global.countries.luxembourg": "Luxemburg",
    "global.countries.netherlands": "Niederlande",
    "global.countries.norway": "Norwegen",
    "global.countries.austria": "Österreich",
    "global.countries.poland": "Polen",
    "global.countries.romania": "Rumänien",
    "global.countries.sweden": "Schweden",
    "global.countries.switzerland": "Schweiz",
    "global.countries.czech": "Tschechien",
    "global.countries.hungary": "Ungarn",
    "global.countries.usa": "USA",
    "global.back.to.login": "Zurück zum Login",
    "global.jackpotHeading": "Gesamt-Budget",
    "language.profile.de": "Deutsch",
    "language.profile.en": "Englisch",
    "language.profile.fr": "Französisch",
    "language.profile.it": "Italienisch",


    // CART
    "cart.no.orders": "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "cart.no.orders.button": "Oder zur Kontaktseite",
    "cart.no.orders.error.hint": "Bitte versuche es noch einmal.",
    "cart.hint": "Bitte beachten Sie, dass aktuell keine Prämienbestellungen möglich sind. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "cart.qty": "Anzahl: ",
    "cart.variant.label": "Variante: ",
    "cart.no.available": "Artikel ist nicht lieferbar!",
    "cart.wishlist.button.remove": "Vom Wunschzettel entfernen",
    "cart.wishlist.button.add": "Auf den Wunschzettel",
    "cart.order.heading": "Status der Bestellung",
    "cart.button.product.remove": "Entfernen",
    "cart.track.order.button": "Sendung verfolgen",
    "cart.product.available": "Artikel sofort lieferbar",
    "cart.product.soon.available": "Artikel ist verfügbar ab ",
    "cart.popup.sum": "Gesamtsumme:",
    "cart.popup.delivery": "Lieferung",
    "cart.popup.first": "Warenkorb",
    "cart.popup.second": "Artikel",
    "cart.credit.box.text1": "Um die Bestellung auszuführen benötigen Sie noch",
    "cart.credit.box.text2": "Punkte. Die fehlenden Punkte für diese Bestellung können Sie für",
    "cart.credit.box.text3": "Euro per Paypal zukaufen",
    "cart.bank.recent": "Ihr Kontostand",
    "cart.credit.box.shipping": "Versand und Lieferkosten inkl.",
    "cart.credit.box.buttun.send": "Zur Bestellung",
    "cart.credit.box.button.more": "Weitere Prämien",
    "cart.shipping_fee_disclaimer": "Aufgrund der von Ihnen gewählten Lieferadresse ergeben sich zusätzliche Versandkosten in Höhe von:",
    "cart.large_shipping_disclaimer": "Bei dem gewählten Artikel handelt es sich um einen Speditionsartikel. Eine Spedition an die von Ihnen gewählte Lieferadresse ist leider nicht möglich.",

    // CHECKOUT
    "checkout.form.adress": "Ausgewählte Lieferadresse",
    "checkout.adress.button.change": "Adresse bearbeiten",
    "checkout.adress.button.safe": "Adresse speichern",
    "checkout.adress.button.cancel": "Abbrechen",
    "checkout.choose.adress": "Wählen Sie Ihre Adresse",
    "checkout.choose.adress.button.send": "An diese Adresse versenden",
    "checkout.choose.adress.button.new": "Neue Adresse hinzufügen",
    "checkout.qty.label": "Anzahl",
    "checkout.qty.products": "Anzahl Artikel: ",
    "checkout.hint.check": "Bitte überprüfen Sie die Bestellung und bestätigen Sie diese mit einem Klick auf Bestellung abschicken",
    "checkout.overview.adress": "Lieferadresse",
    "checkout.mail.check": "Sie erhalten eine Bestätigung per E-Mail an folgende Adresse: ",
    "checkout.order.no": "Bestell-NR.",
    "checkout.order.date": "Bestelldatum",
    "checkout.order.show": "Bestellung ansehen",

    "checkout.back.to.cart": "Zurück zum Warenkorb",
    "checkout.change.adress.button": "Lieferadresse ändern",
    "checkout.agb.check1": "Ich erkläre mich mit den ",
    "checkout.agb.check2": "Teilnahmebedingungen",
    "checkout.agb.check3": " von Buben & Mädchen GmbH einverstanden.",
    "checkout.load.points.button": "Punkte aufladen",
    "checkout.contact.button": "Zum Kontaktformular",
    "checkout.confirm.order.button": "Bestellung abschicken",
    "checkout.no.orders.possible": "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.no.orders.hint": "Bitte beachten Sie, dass aktuell keine Prämienbestellungen möglich sind. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.modal.no.points": "Fehlende Punkte",
    "checkout.modal.currency": "€",
    "checkout.modal.currency.long": "EUR",
    "checkout.modal.payment.check": "Zuzahlung abschließen",
    "checkout.modal.transaction.success": "Transaktion erfolgreich, ID",
    "checkout.modal.close.button": "Schließen",
    "checkout.modal.step1": "Punkte kaufen",
    "checkout.modal.step2": "Zahlungsmethode",
    "checkout.modal.step3": "Transaktion war erfolgreich",
    "checkout.breadcrumb.button1": "Adresse",
    "checkout.breadcrumb.button2": "Übersicht",
    "checkout.breadcrumb.button3": "Bestätigung",

    // PROFILE
    "profile.page.heading": "Ihre Persönlichen Daten",
    "profile.page.pw.now": "Geben Sie bitte Ihr aktuelles Passwort ein",
    "profile.page.pw.no.empty": "Das Passwort darf nicht leer sein",
    "profile.page.pw.not.same": "Passwörter stimmen nicht überein",
    "profile.page.pw.min.txt": "Ihr gewähltes Passwort entspricht nicht den Sicherheitsbestimmungen. Ihr Passwort muss aus mindestens 8 Zeichen bestehen und Groß- und Kleinschreibung und mindestens eine Zahl beinhalten.",

    // CONTACT
    "contact.page.choose": "Bitte wählen:",
    "contact.page.success": "Nachricht wurde erfolgreich versendet!",
    "contact.page.help1": "Allgemeine Fragen",
    "contact.page.help2": "Fragen zu Wertungskriterien / Punktebuchung / Spielregeln",
    "contact.page.help3": "Fragen zu Bestellungen und Prämien",
    "contact.page.help3.special": "Fragen zu Bestellungen und Prämien",
    "contact.page.heading": "Bitte wählen Sie den Grund Ihrer Anfrage aus.",
    "contact.page.reason": "Kontaktgrund",
    "contact.page.order.nr": "Bestellnummer (TB.xxx) oder Artikelnummer (BM-xxx)",
    "contact.page.message": "Ihre Nachricht",
    "contact.page.message.placeholder": "Sehr geehrte Damen und Herren..",
    "contact.page.send": "Abschicken",
    "contact.page.info": "Schreiben Sie uns eine Mail. Wir sind von Montag bis Freitag von 9:00 – 18:00 Uhr für Sie da.",

    // MENU
    "menu.main.categories": "Hauptkategorien",
    "menu.highlights": "Highlights",
    "menu.all.novelties": "Alle Neuheiten anzeigen",
    "menu.award.category": "Prämienkategorie",
    "menu.sub.categories": "Unterkategorien",
    "menu.welcome.text": "Hallo",
    "menu.edit.profile": "Profil bearbeiten",
    "menu.conditions.participation": "Teilnahmebedingungen",
    "menu.terms.delivery": "Lieferbedingungen",
    "menu.contact": "Kontakt",
    "menu.logout": "Logout",
    "menu.home": "Home",
    "menu.ratings": "Wertungen",
    "menu.ranking": "Rangfolge",
    "menu.my.contracts": "Meine Verträge",
    "menu.sort.contracts": "Vertragszuordnung",
    "menu.points.account": "Punktekonto",
    "menu.shopping.cart": "Warenkorb",
    "menu.order.status": "Bestellstatus",
    "menu.wishlist": "Wunschliste",
    "menu.news": "News",
    "menu.award.shop": "Prämienshop",
    "menu.search": "Suche",

    // SHOP
    "shop.delivery1.msg1": "Artikel lieferbar innerhalb von ",
    "shop.delivery1.msg1.day": " Wochen",
    "shop.delivery2.msg2": "Artikel lieferbar in ",
    "shop.delivery2.msg2.day": " Werktagen",

    // APP
    "app.more.info": "Mehr Informationen",
    "app.less.info": "Weniger Informationen",
    "app.currency": "Euro",
    "app.currency_points": "Einheiten",
    "app.sales": "Umsatz",
    "app.profit": "Gewinn",
    "app.points": "PKT",
    "app.contracts": "Verträge",
    "app.contracts.number": "Anzahl Vertragspunkte",
    "app.contracts.number.previous": "Anzahl Vertragspunkte im Vorjahr",
    "app.sales.previous": "Umsatz im Vorjahr",
    "app.goals": "Ziel",
    "app.goals.target": "Ihre Zielvorgabe:",
    "app.days": "Tage",
    "app.hours": "Stunden",
    "app.mins": "Minuten",
    "app.award.news": "Prämienneuheiten - schon entdeckt?",
    "app.award.highlights": "Prämien-Highlights",
    "app.remaining.time": "Restliche Zeit bis zum Aktionsende",
    "app.your.performance": "Ihre Performance im Vergleich zum Ziel",
    "app.your.target": "Ihre Zielerreichung im Vergleich zum Vorjahr",
    "app.your.target.reached": "Herzlich Glückwunsch, Sie haben Ihre Zielvorgabe erreicht! Starke Leistung, weiter so!",
    "app.lets.go": "Auf geht’s, Ihnen fehlen noch ",
    "app.next.win": " zur nächsten Gewinnstufe.",
    "app.attack": "Attacke! Überbieten Sie Ihre Zielvorgaben",
    "app.goal": "Ihr Ziel: Seien Sie besser als im Vorjahr!",
    "app.you.achieved": "Das haben Sie bereits geschafft:",
    "app.win.levels": "Gewinnstufen – Das können Sie gewinnen",
    "app.video.no.support": "Ihr Browser unterstützt das Video-Tag nicht. Bitte aktualisieren Sie Ihren Browser.",
    "app.campaign.end": "Die Super Sales Aktion endet in:",
    "app.sales.performance": "Ihre Sales Performance",
    "app.sales.national": "National",
    "app.sales.per.contract": "Umsatz pro Vertrag",
    "app.sales.status": "Ihr Stand",
    "app.your.place": "DEINE PLATZIERUNG",
    "app.current.level": "Aktuelle Gewinnstufe",
    "app.win.level": "Gewinnstufen",
    "app.place": "Platz",
    "app.overall.ranking": "Gesamt-Ranking",
    "app.sales.contract": "Umsatz der Verträge",
    "app.contracts.closed": "Vertragspunkte",
    "app.herosplace.ranking": "HEROSPLACE RANKING",
    "app.back.win.level": "Zurück zu Gewinnstufen",
    "app.to.overall.ranking": "Zum Gesamt-Ranking",
    "app.choose.challenge": "Bounties - Wählen sie eine challenge aus",
    "app.challenge": "Challenge",
    "app.target.value": "Ziel-Wert",
    "app.your.profit": "Ihr Gewinn",
    "app.schaffeich": "Ja, das schaffe ich!",
    "app.this.challenge": "Diese Challenge wählen",
    "app.personal.challenge": "Ihre Persönliche Challenge",
    "app.target.achievement": "Zielerreichung",
    "app.actual.value": "IST WERT",
    "app.target.value.challenge": "ZIEL WERT",
    "app.win.target.achievement": "Gewinn bei Zielerreichung",
    "app.promotional.period.ends": "Aktionszeitraum endet in:",
    "app.time.choose.challenge": "Zeit für die Auswahl Ihrer Challenge!",
    "app.save.challenge": "Challenge speichern",
    "app.award.search": "Prämiensuche",
    "app.award.search.keyword": "Suchbegriff",
    "app.award.search.points.from": "Punkte von",
    "app.award.search.points.to": "..bis",
    "app.award.search.start": "Suche starten",
    "app.costumer": "Kunden",
    "app.new.costumer.barometer": "Neukunden-Barometer",
    "app.already.achieved": "Das hast du bereits erreicht",
    "app.acquired.new.customers": "Akquirierte Neukunden",
    "app.contracts.missing": "Verträge, die dir bis zur nächsten Stufe fehlen.",
    "app.number.new.costumers": "Neukunden-Anzahl",
    "app.gain.points": "Punkte-Gewinn",
    "app.all.win.steps": "Alle Gewinnstufen",
    "app.available.games": "Verfügbare Spiele",
    "app.equals.closed.contracts": "Entspricht Ihren abgeschlossenen Verträgen",
    "app.have.fun": "Viel Spaß!",
    "app.points.bandit": "Punkte-Bandit",
    "app.bandit.info.klick": "Klicken Sie auf den einarmigen ",
    "app.bandit.info.game": "Punkte-Banditen und spielen Sie.",
    "app.points.safe":  "Punktetresor",
    "app.point":  "Punkte",
    "app.points.safe.info": "Mit jedem Punktegewinn reduziert sich der allgemeine Punktetresor. Sobald der Tresor leer ist endet die Aktion.",
    "app.our.sales.target": "Unser Umsatzziel",
    "app.until.the": "bis zum ",
    "app.current.sales": "Aktueller Umsatz",
    "app.currency.icon": "€",
    "app.information": "Informationen",
    "app.plus": "PLUS",
    "app.bonus.ammount": "Bonus in Höhe von",
    "app.deadline": "Deadline",
    "app.monthly.award.points": "Jeden Monat schreiben wir Dir Prämienpunkte im Wert von 44 Euro gut. Dies ist eine Sachzuwendung, die für Dich komplett steuerfrei ist. Mit Deinen Punkten kannst Du aus über 3.000 tollen Produkten Deine persönliche Wunschprämie zum Zeitpunkt Deiner Wahl aussuchen. Viel Spaß!",
    "app.my.points": "MEINE PUNKTE",
    "app.news.award.club": "News aus dem Prämien-Club",
    "app.new": "NEU",
    "app.read.on": "weiterlesen",
    "app.read.all.news": "Alle News lesen",
    "app.jackpot.counter": "JACKPOT-COUNTER",
    "app.still.in.jackpot": "Sind noch im Jackpot",
    "app.your.point.status": "Ihr Punktestand",
    "app.back.to.overview": "Zurück zur Übersicht",
    "app.category": "Kategorie",
    "app.award.variant": "Prämien-Variante",
    "app.put.in.cart": "In den Warenkorb",
    "app.award.despcription": "Prämienbeschreibung",
    "app.main.category": "Hauptkategorie",
    "app.sub.category": "Unterkategorie",
    "app.choose.main.category": "Bitte wählen Sie eine Hauptkategorie.",
    "app.shop.search": "Suchen",
    "app.shop.sort.for": "Sortieren nach",
    "app.shop.sort.for.newest": "Neuste Prämien",
    "app.shop.sort.for.az": "Alphabetisch A-Z",
    "app.shop.sort.for.za": "Alphabetisch Z-A",
    "app.password.not.match": "Passwörter stimmen nicht überein.",
    "app.sign.up.step.one": "Schritt 1/2",
    "app.sign.up.declare": "Ich erkläre mich mit den ",
    "app.sign.up.agree": " von Buben & Mädchen GmbH einverstanden. ",
    "app.sign.up.just_agree": " einverstanden. ",
    "app.continue": "Weiter",
    "app.privacy.policy": "Datenschutzbedingungen",
    "app.privacy.disclaimer": "Ich bin damit einverstanden, dass meine personenbezogenen Daten, durch die in den Datenschutzbestimmungen ausgewiesene Stelle, im Rahmen meiner Teilnahme am genannten Programm, zum Zwecke der Teilnahme am Incentive verarbeitet werden. Mir ist bekannt, dass ausschließlich diejenigen personenbezogenen Daten verarbeitet werden, die zur Teilnahme an dem Incentive notwendig sind. Die jeweiligen Daten werden von mir selbst angegeben. Mir ist außerdem bekannt, dass ich meine Einwilligung jederzeit durch Erklärung gegenüber der in den Datenschutzbestimmungen ausgewiesene Stelle durch einfache Erklärung widerrufen kann. Mir stehen außerdem die Rechte nach dem Dritten Kapitel der DS-GVO auf Auskunft, Berichtigung, Löschung, Datenübertragbarkeit, Einschränkung der Verarbeitung, Widerspruch und auf Beschwerde gegenüber einer Aufsichtsbehörde zu.",
    "app.sign.up.step.two": "Schritt 2/2",
    "app.company": "Firma",
    "app.company.placeholder": "Firma XY",
    "app.salutation": "Anrede",
    "app.mr": "Herr",
    "app.ms": "Frau",
    "app.dr": "Dr",
    "app.firstname": "Vorname",
    "app.lastname": "Nachname",
    "app.firstname.placeholder": "Max",
    "app.lastname.placeholder": "Mustermann",
    "app.street": "Straße & Hausnummer",
    "app.street.placeholder": "Musterstraße",
    "app.plz": "PLZ*",
    "app.plz.placeholder": "12345",
    "app.city": "Ort*",
    "app.city.placeholder": "Ort",
    "app.country": "Land",
    "app.country.placeholder": "Land",
    "app.birthday": "Geburtsdatum",
    "app.birthday.placeholder": "01.03.1960",
    "app.email_short": "E-Mail",
    "app.email": "E-Mail Adresse*",
    "app.email_your": "Ihre E-Mail Adresse*",
    "app.email.placeholder": "m.mustermann@muster.de",
    "app.phone": "Telefon",
    "app.phone.placeholder": "1234 567890",
    "app.handy": "Handy-Nummer",
    "app.handy.placeholder": "1234 567890",
    "app.customer": "Firma",
    "app.customer.placeholder": "Firma",
    "app.customer.nr": "HDL-Nr",
    "app.customer.nr.placeholder": "123-456-789",
    "app.agree.checkbox": "Hiermit erkläre ich mich einverstanden, regelmäßig Programminformationen zu erhalten.",
    "app.profile.pic.heading": "IHR PROFILBILD",
    "app.profile.pic.change": "Zum ändern klicken …",
    "app.profile.pic.drop": "Ziehen Sie Ihr Bild in diese Fläche …",
    "app.profile.pic.delete": "Profilbild löschen",
    "app.profile.pic.safe": "Profilbild speichern",
    "app.profile.add.adress": "Adresse hinzufügen",
    "app.profile.all.adresses": "Ihre Lieferadressen",
    "app.profile.adress.phone": "Telefon:",
    "app.profile.adress.mail": "E-Mail:",
    "app.profile.adress.settings": "Bearbeiten",
    "app.profile.adress.remove": "Entfernen",
    "app.profile.adress.change": "Adresse bearbeiten",
    "app.profile.adress.new": "Neue Adresse",
    "app.profile.adress.changes.safe": "Änderungen speichern",
    "app.profile.adress.new.safe": "Neue Adresse speichern",
    "app.profile.login.heading": "Ihre Logindaten",
    "app.profile.login.current.pw": "Aktuelles Passwort",
    "app.profile.login.new.pw": "Neues Passwort",
    "app.profile.login.new.pw.same": "Neues Passwort wiederholen",
    "app.profile.login.new.pw.safe": "Neues Passwort speichern",
    "app.safe.data": "Daten speichern",
    "app.personal.data": "Persönliche Daten",
    "app.sign.up.complete": "Registrierung – bitte Daten vervollständigen",
    "app.sign.up.check.data": "Hier können Sie im letzten Registrierungsschritt noch einmal Ihre persönlichen Daten einsehen, überprüfen und auch korrigieren. Wenn Sie Änderungen an Vor- und Nachnamen vornehmen, wenden Sie sich bitte an unser Supportteam. Bitte ändern Sie auch Ihr Passwort hier noch einmal.",
    "app.new.password_short": "Neues Passwort",
    "app.new.password": "Ihr neues Passwort",
    "app.new.password_save": "Neues Passwort speichern",
    "app.new.password.repeat": "Bitte wiederholen Sie Ihr Passwort",
    "app.award.code": "Prämien-Code",
    "app.award.code.placeholder": "Code",
    "app.award.code.redeem": "Einlösen",
    "app.exclusive.award.collection": "IHRE EXKLUSIVE PRÄMIENAUSWAHL",
    "app.code.invalid": "Code nicht gültig.",
    "app.forgot.password": "Passwort vergessen?",
    "app.promo.booster": "PROMO-BOOSTER",
    "app.code.collect": "MIT PROMO-CODE PUNKTE SAMMELN",
    "app.welcome.at.company": "Willkommen bei der Musterfirma-PrämienParty!",
    "app.code.collect.redeem": "Sie haben ein Aktionsprodukt erworben?! Super, dann können Sie gleich hier den Promotion-Code (oder mehrere) einlösen. Die entsprechenden Punkte werden automatisch Ihrem Punktekonto gutgeschrieben. Anschließend können Sie sich nach Lust und Laune Ihre Wunschprämie aussuchen.",
    "app.register.via.code": "Zur Registrierung via Promo-Code",
    "app.code.to.sign.up": "Geben Sie hier Ihren Promocode ein, um sich im XY Promocode Portal ein Konto einzurichten.",
    "app.code": "Promocode",
    "app.code.redeem": "Code einlösen",
    "app.already.customer": "Ich bin bereits Kunde",
    "app.ranking": "RANKING - MEINE PLATZIERUNG",
    "app.belong.to.group": "Sie gehören zur Gruppe",
    "app.rank": "Rang",
    "app.name": "Name",
    "app.rank.points": "Rankingpunkte",
    "app.evaluation.criteria": "Wertungskriterien",
    "app.your.current.rank": "Ihr aktueller Rang",
    "app.show.rank": "Ranking anzeigen",
    "app.news.no.heading": "Keine News vorhanden",
    "app.news.no.button": "Zu den Prämien wechseln",
    "app.profile.language": "Sprache",

    // Salesmarathon
    "app.salesmarathon.goals": "Ziel",
    "app.salesmarathon.profit": "Bonus",
    "app.salesmarathon.lets.go": "Auf geht´s, erreichen Sie ihr Ziel!",
    "app.salesmarathon.currency1": "pro",
    "app.salesmarathon.currency2": "Umsatz",

    // Salestriathlon
    "app.salestriathlon.month.heading": "Deine Monatsziele",
    "app.salestriathlon.goals.heading": "Deine Zielerreichung",
    "app.salestriathlon.all.goals.heading": "Dein Gesamtziel der Aktion",
    "app.salestriathlon.all.goals.text": "Solltest Du ein Monatsziel nicht erreichen, kannst Du trotzdem noch die volle Punktzahl gewinnen, wenn Du am Ende der Aktion Dein Gesamtziel (Summe aller Monate) erreichst. In diesem Sinne: GIB ALLES!",
    "app.salestriathlon.finish.goals.heading": "Deine Gesamtziel-Erreichung",
    "app.salestriathlon.your.reward": "Deine Belohnung",
    "app.salestriathlon.first.month": "Erster Monat",
    "app.salestriathlon.second.month": "Zweiter Monat",
    "app.salestriathlon.third.month": "Dritter Monat",
    "app.salestriathlon.goals.contracts1": "Schließe",
    "app.salestriathlon.goals.contracts2": "Verträge ab!",
    "app.salestriathlon.goals.sales1": "Generiere",
    "app.salestriathlon.goals.sales2": "Umsatz",


    //404
    "page.404.headline": "OCH NEE ...",
    "page.404.subline": "Da funktioniert was nicht.",
    "page.404.info": "Entweder kam es zu einem schrecklichen Fehler, oder die Seite existiert nicht. Aber keine Sorge – wir helfen dir weiter!",
    "page.404.action.home": "Zur Startseite",
    "page.404.action.contact": "Kontakt aufnehmen",
    "page.404.domain.headline": "Vielleicht vertippt?",
    "page.404.domain.info": "Bitte überprüfen Sie die eingegebene URL. So ungefähr muss sie lauten:",
  },

  // ===== EN =====

  "en": {
    // Login
    "login.h1": "Welcome",
    "login.h2.subline": "In your customer portal",
    "login.h2.login": "Login",
    "login.label.username": "E-Mail *",
    "login.input.username": "E-Mail",
    "login.label.password": "Password *",
    "login.input.password": "Password",
    "login.button": "Login",
    "login.forgotten.password": "Did u forgot your password?",
    "login.error.info": "Something has gone wrong. Please check your entered data or contact our support.",
    "login.new.password": "Set a new one.",
    "login.contact.support": "You can't log in? Please check your access data which you have received from us by mail.",
    "login.service.mail": "service@incentivearena.de",
    "login.data.protection": "Data protection",
    "login.imprint": "Imprint",
    "login.contact": "Contact",
    "login.password_policy": "Please enter a new password. This must contain at least 8 characters, upper and lower case and at least one number.",
    "login.error.mail": "The e-mail address must not be empty",
    "login.error.password_policy": "Your chosen password does not comply with the security regulations. Your password must contain at least 8 characters, upper and lower case letters and at least one number.",
    "login.error.password_mismatch": "Passwords do not match!",
    "login.error.password_is_empty": "The password must not be empty",
    "login.password_reset.headline": "Reset password",
    "login.password_reset.description": "Here you have the possibility to reset your password. As soon as you confirm your e-mail address, you will be asked to enter a new password.",
    "login.password_reset.email_success": "We have sent you a link to change your password to your e-mail address.",
    "login.password_reset.success": "Great, that worked! The password has been successfully reset.",
    "login.userdata.headline": "Data completion",
    "login.tos.headline": "Conditions of participation",
    "login.credentials.error": "The email address or password is incorrect.",

    // Global
    "global.close.info": "Close infos",
    "global.mandatory.fields": "* Required fields",
    "global.open.info": "Further infos",
    "global.rules": "The rules of the game",
    "global.rules.short": "rules",
    "global.points.account": "Current account balance",
    "global.award.overview": "Continue",
    "global.recommendation": "Our recommendations",
    "global.loading": "Loading...",
    "global.your.cart": "Your shopping cart",
    "global.your.order": "Your order",
    "global.less.info": "Less info",
    "global.back_to_login": "Back to login",
    "global.next": "Next",
    "global.account.heading": "Score",
    "global.account.source": "Source",
    "global.account.date": "Date",
    "global.account.desc": "Description",
    "global.account.points": "Points",
    "global.account.sum": "Total in period",
    "global.account.old.points": "Current balance",
    "global.empty.table": "No entries available",
    "global.change.products": "Go to rewards",
    "global.cart.empty": "Your shopping cart is empty.",
    "global.wishlist.button": "Show my wish list",
    "global.button.go.to.contact": "To the contact form",
    "global.badge.neuheit": "Novelty",
    "global.badge.highlight": "Highlight",
    "global.badge.luxus": "Luxury premium",
    "global.key.new": "NEW",
    "global.order.view": "Order overview",
    "global.order.button.view": "To the order overview",
    "global.back.to.homepage": "Back to home page",
    "global.something.went.wrong": "Oops, something went wrong.",
    "global.confirmation.heading": "Thank you, we have received your order.",
    "global.confirmation.text": "In the next few minutes you will receive an order confirmation to the email address you provided. If you have any questions about your order, please check your order status under „Account / Order Status“. There you can see the current shipping status of your order at any time. We wish you the fun with your premium.",
  "global.no.orders.in.time": "No orders available in the period.",
  "global.no.orders": "No orders available.",
  "global.switch.to.products": "Switch to rewards",
  "global.accordion.orders.heading": "Period filter",
  "global.flatpicker.start": "from",
  "global.flatpicker.end": "to",
  "global.flatpicker.date": "Select date..",
  "global.flatpicker.button": "Reload",
  "global.wishlist.page.hint": "You can use your wish list to permanently save premium requests. To do this, place the premium on your wish list. As soon as you have enough points to place an order, you will be automatically informed by e-mail and can easily transfer the items from the wish list into an order.",
  "global.wishlist.page.remove.all": "Remove all items",
  "global.wishlist.page.add.all": "Add all items to shopping cart",
  "global.wishlist.page.empty": "Your wish list is empty.",
  "global.wishlist.page.button.cart": "Go to shopping cart",
  "global.profile.page.heading": "Your profile",
  "global.homepage.start": "Let's go",
  "global.countries.germany": "Germany",
  "global.countries.belgium": "Belgium",
  "global.countries.denmark": "Denmark",
  "global.countries.finnland": "Finnland",
  "global.countries.france": "France",
  "global.countries.uk": "United Kingdom",
  "global.countries.italy": "Italy",
  "global.countries.luxembourg": "Luxembourg",
  "global.countries.netherlands": "Netherlands",
  "global.countries.norway": "Norway",
  "global.countries.austria": "Austria",
  "global.countries.poland": "Poland",
  "global.countries.romania": "Romania",
  "global.countries.sweden": "Sweden",
  "global.countries.switzerland": "Switzerland",
  "global.countries.czech": "Czech Republic",
  "global.countries.hungary": "Hungary",
  "global.countries.usa": "USA",
  "global.back.to.login": "Back to Login",
  "global.jackpotHeading": "Total-Budget",
  "language.profile.de": "German",
  "language.profile.en": "English",
  "language.profile.fr": "French",
  "language.profile.it": "Italian",

  // CART
  "cart.no.orders": "You cannot order any premium articles at present because you are subject to an order block. If you have any questions about this, please get in touch with your contact person.",
  "cart.no.orders.button": "Or to the contact page",
  "cart.no.orders.error.hint": "Please try it again.",
  "cart.hint": "Please note that it is currently not possible to order premium items. Should you have any queries regarding this, please contact your relevant contact person.",
  "cart.qty": "Quantity: ",
  "cart.variant.label": "Variant: ",
  "cart.no.available": "Item is not available!",
  "cart.wishlist.button.remove": "Remove from wish list",
  "cart.wishlist.button.add": "Add to wish list",
  "cart.order.heading": "Order status",
  "cart.button.product.remove": "Remove",
  "cart.track.order.button": "Track shipment",
  "cart.product.available": "Item available immediately",
  "cart.product.soon.available": "Item is available from ",
  "cart.popup.sum": "Total amount:",
  "cart.popup.delivery": "Delivery",
  "cart.popup.first": "Shopping cart",
  "cart.popup.second": "Article",
  "cart.credit.box.text1": "To complete the order you still need",
  "cart.credit.box.text2": "points. You can buy the missing points for this order for",
  "cart.credit.box.text3": "Euro via Paypal to buy additional points",
  "cart.bank.recent": "Your account balance",
  "cart.credit.box.shipping": "Shipping and delivery costs included",
  "cart.credit.box.buttun.send": "To the order",
  "cart.credit.box.button.more": "More rewards",

  // CHECKOUT

  "checkout.form.adress": "Selected delivery address",
  "checkout.adress.button.change": "Edit address",
  "checkout.adress.button.safe": "Save address",
  "checkout.adress.button.cancel": "Cancel",
  "checkout.choose.adress": "Select your address",
  "checkout.choose.adress.button.send": "Ship to this address",
  "checkout.choose.adress.button.new": "Add new address",
  "checkout.qty.label": "Quantity",
  "checkout.qty.products": "Quantity of items: ",
  "checkout.hint.check": "Please check the order and confirm it by clicking on Submit Order",
  "checkout.overview.adress": "Delivery address",
  "checkout.mail.check": "You will receive a confirmation by e-mail to the following address: ",
  "checkout.back.to.cart": "Back to shopping cart",
  "checkout.change.adress.button": "Change delivery address",
  "checkout.agb.check1": "I agree to the ",
  "checkout.agb.check2": "Conditions of Participation",
  "checkout.agb.check3": " of Buben & Mädchen GmbH.",
  "checkout.load.points.button": "Recharge points",
  "checkout.contact.button": "To the contact form",
  "checkout.confirm.order.button": "Send order",
  "checkout.no.orders.possible": "You cannot order any premium items at the moment because you are subject to an order block. If you have any queries in this regard, please contact your responsible contact person.",
  "checkout.no.orders.hint": "Please note that it is currently not possible to order rewards. If you have any questions, please contact your contact person.",
  "checkout.modal.no.points": "Missing points",
  "checkout.modal.currency": "€",
  "checkout.modal.currency.long": "EUR",
  "checkout.modal.payment.check": "Complete additional payment",
  "checkout.modal.transaction.success": "Transaction successful, ID",
  "checkout.modal.close.button": "Close",
  "checkout.modal.step1": "Buy points",
  "checkout.modal.step2": "Payment method",
  "checkout.modal.step3": "Transaction was successful",
  "checkout.breadcrumb.button1": "Address",
  "checkout.breadcrumb.button2": "Overview",
  "checkout.breadcrumb.button3": "Confirmation",
  "checkout.order.no": "Order no.",
  "checkout.order.date": "Order date",
  "checkout.order.show": "View order",

  // PROFILE
  "profile.page.heading": "Your personal data",
  "profile.page.pw.now": "Please enter your current password",
  "profile.page.pw.no.empty": "Password must not be empty",
  "profile.page.pw.not.same": "Passwords do not match",
  "profile.page.pw.min.txt": "Your chosen password does not comply with the security regulations. Your password must be at least 8 characters long and contain upper and lower case letters and at least one number.",

  // CONTACT
  "contact.page.choose": "Please choose:",
  "contact.page.success": "Message was sent successfully!",
  "contact.page.help1": "General questions",
  "contact.page.help2": "Questions about scoring criteria / points booking / rules of the game",
  "contact.page.help3": "Questions about orders and rewards",
  "contact.page.help3.special": "Questions about orders and rewards",
  "contact.page.heading": "Please select the reason for your enquiry.",
  "contact.page.reason": "Contact reason",
  "contact.page.order.nr": "Order number (TB.xxx) or item number (BM-xxx)",
  "contact.page.message": "Your message",
  "contact.page.message.placeholder": "Dear Sir or Madam...",
  "contact.page.send": "Send",
  "contact.page.info": "Send us an email. We are there for you from Monday to Friday from 9:00 - 18:00.",

    // MENU
    "menu.main.categories": "Main categories",
    "menu.highlights": "Highlights",
    "menu.all.novelties": "Show all novelties",
    "menu.award.category": "Award category",
    "menu.sub.categories": "Subcategories",
    "menu.welcome.text": "Hello",
    "menu.edit.profile": "Edit profile",
    "menu.conditions.participation": "Conditions of participation",
    "menu.terms.delivery": "Terms of delivery",
    "menu.contact": "Contact",
    "menu.logout": "Logout",
    "menu.home": "Home",
    "menu.ratings": "Ratings",
    "menu.ranking": "Ranking",
    "menu.my.contracts": "My contracts",
    "menu.sort.contracts": "Contract assignment",
    "menu.winners.trip": "Winners' Prize",
    "menu.points.account": "Points account",
    "menu.shopping.cart": "Cart",
    "menu.order.status": "Order status",
    "menu.wishlist": "Wish list",
    "menu.news": "News",
    "menu.award.shop": "Reward Shop",
    "menu.gamerules": "The rules of the game",
    "menu.search": "Search",

    // SHOP
    "shop.delivery1.msg1": "Item available within ",
    "shop.delivery1.msg1.day": " weeks",
    "shop.delivery2.msg2": "Article available in ",
    "shop.delivery2.msg2.day": " working days",

    // APP
    "app.more.info": "+ Infos",
    "app.less.info": "Less info",
    "app.currency": "Euro",
    "app.currency_points": "Units",
    "app.sales": "Sales",
    "app.profit": "Profit",
    "app.points": "PTS",
    "app.contracts": "Contracts",
    "app.contracts.number": "Number of contract points",
    "app.contracts.number.previous": "Number of contract points in previous year",
    "app.sales.previous": "Sales in previous year",
    "app.goals": "Target",
    "app.goals.target": "Your target:",
    "app.days": "Days",
    "app.hours": "Hours",
    "app.mins": "Minutes",
    "app.award.news": "Premium novelties - already discovered?",
    "app.award.highlights": "Premium highlights",
    "app.remaining.time": "Remaining time until the end of the action",
    "app.your.performance": "Your performance compared to the target",
    "app.your.target": "Your goal achievement compared to the previous year",
    "app.your.target.reached": "Congratulations, you have reached your goal! Strong performance, keep it up!",
    "app.lets.go": "Let's go, you are still missing ",
    "app.next.win": " to the next winning level.",
    "app.attack": "Attack! Beat your targets",
    "app.goal": "Your goal: Be better than last year!",
    "app.you.achieved": "You have already done that:",
    "app.win.levels": "Win levels - What you can win",
    "app.video.no.support": "Your browser does not support the video tag. Please upgrade your browser.",
    "app.campaign.end": "The Super Sales promotion ends in:",
    "app.sales.performance": "Your Sales Performance",
    "app.sales.national": "National",
    "app.sales.per.contract": "Sales per contract",
    "app.sales.status": "Your Stand",
    "app.your.place": "YOUR STANDINGS",
    "app.current.level": "Current profit level",
    "app.win.level": "Profit levels",
    "app.place": "Rank",
    "app.overall.ranking": "Overall ranking",
    "app.sales.contract": "Contract turnover",
    "app.contracts.closed": "Contract points",
    "app.herosplace.ranking": "HEROSPLACE RANKING",
    "app.back.win.level": "Back to profit levels",
    "app.to.overall.ranking": "To the overall ranking",
    "app.choose.challenge": "Bounties - Select a challenge",
    "app.challenge": "Challenge",
    "app.target.value": "Target value",
    "app.your.profit": "Your profit",
    "app.schaffeich": "Yes, I can do it!",
    "app.this.challenge": "Choose this Challenge",
    "app.personal.challenge": "Your Personal Challenge",
    "app.target.achievement": "Goal achievement",
    "app.actual.value": "IS VALUE",
    "app.target.value.challenge": "GOAL VALUE",
    "app.win.target.achievement": "Win if target is reached",
    "app.promotional.period.ends": "Promotion period ends in:",
    "app.time.choose.challenge": "Time to choose your challenge!",
    "app.save.challenge": "Save Challenge",
    "app.award.search": "premium search",
    "app.award.search.keyword": "Search Keyword",
    "app.award.search.points.from": "Points from",
    "app.award.search.points.to": "..to",
    "app.award.search.start": "Start search",
    "app.costumer": "Customers",
    "app.new.costumer.barometer": "New customer barometer",
    "app.already.achieved": "What you have already achieved",
    "app.acquired.new.customers": "New customers acquired",
    "app.contracts.missing": "Contracts you are missing until the next level.",
    "app.number.new.costumers": "Number of new customers",
    "app.gain.points": "Points won",
    "app.all.win.steps": "All levels won",
    "app.available.games": "Games available",
    "app.equals.closed.contracts": "Corresponds to the contracts you have signed",
    "app.have.fun": "Have fun!",
    "app.points.bandit": "Points Bandit",
    "app.bandit.info.klick": "Click on the one-armed ",
    "app.bandit.info.game": "points bandit and play.",
    "app.points.safe": "Points Vault",
    "app.point": "Points",
    "app.points.safe.info": "Each time you win points, the general points vault is reduced. Once the vault is empty, the promotion ends.",
    "app.our.sales.target": "Our turnover target",
    "app.until.the": "until ",
    "app.current.sales": "Current turnover",
    "app.currency.icon": "€",
    "app.information": "Information",
    "app.plus": "PLUS",
    "app.bonus.ammount": "Bonus in the amount of",
    "app.deadline": "Deadline",
    "app.monthly.award.points": "Every month we will credit you with bonus points worth €44. This is a gift in kind that is completely tax-free for you. You can use your points to choose your personal reward from over 3,000 great products at the time of your choice. Have fun!",
    "app.my.points": "MY POINTS",
    "app.news.award.club": "News from the Reward Club",
    "app.new": "NEW",
    "app.read.on": "read more",
    "app.read.all.news": "Read all news",
    "app.jackpot.counter": "JACKPOT-COUNTER",
    "app.still.in.jackpot": "Are still in the jackpot",
    "app.your.point.status": "Your score",
    "app.back.to.overview": "Back to the overview",
    "app.category": "Category",
    "app.award.variant": "Premium variant",
    "app.put.in.cart": "Add to shopping cart",
    "app.award.despcription": "Premium description",
    "app.main.category": "Main category",
    "app.sub.category": "Subcategory",
    "app.choose.main.category": "Please select a main category.",
    "app.shop.search": "Search",
    "app.shop.sort.for": "Sort by",
    "app.shop.sort.for.newest": "Latest premiums",
    "app.shop.sort.for.az": "Alphabetical A-Z",
    "app.shop.sort.for.za": "Alphabetical Z-A",
    "app.password.not.match": "Passwords do not match",
    "app.sign.up.step.one": "Step 1/2",
    "app.sign.up.declare": "I agree to the ",
    "app.sign.up.agree": " of Buben & Mädchen GmbH agree ",
    "app.sign.up.just_agree": " ",
    "app.continue": "Continue",
    "app.privacy.policy": "Data protection conditions",
    "app.privacy.disclaimer": "I agree that my personal data may be processed by the office indicated in the data protection regulations within the scope of my participation in the above-mentioned programme for the purpose of participating in the incentive. I understand that only personal data necessary for participation in the incentive will be processed. The respective data will be provided by myself. I am also aware that I may revoke my consent at any time by making a simple declaration to the office indicated in the data protection regulations. I am also entitled to the rights under Chapter Three of the GDPR to information, correction, deletion, data portability, restriction of processing, objection and to lodge a complaint with a supervisory authority.",
    "app.sign.up.step.two": "Step 2/2",
    "app.company": "Company",
    "app.company.placeholder": "Company XY",
    "app.salutation": "Salutation",
    "app.mr": "Mr",
    "app.ms": "Mrs",
    "app.dr": "Dr",
    "app.firstname": "First name",
    "app.lastname": "Last name",
    "app.firstname.placeholder": "Jon",
    "app.lastname.placeholder": "Doe",
    "app.street": "Street & house number",
    "app.street.placeholder": "Sample Street",
    "app.plz": "POSTCODE*",
    "app.plz.placeholder": "12345",
    "app.city": "City*",
    "app.city.placeholder": "City",
    "app.country": "Country",
    "app.country.placeholder": "Country",
    "app.birthday": "Date of birth",
    "app.birthday.placeholder": "21976",
    "app.email_short": "E-mail",
    "app.email": "E-mail address*",
    "app.email_your": "Your e-mail address*",
    "app.email.placeholder": "m.mustermann@muster.de",
    "app.phone": "Phone",
    "app.phone.placeholder": "1234 567890",
    "app.handy": "Mobile number",
    "app.handy.placeholder": "1234 567890",
    "app.customer": "Company",
    "app.customer.placeholder": "Company",
    "app.customer.nr": "Company number",
    "app.customer.nr.placeholder": "123-456-789",
    "app.agree.checkbox": "I hereby agree to receive regular programme information.",
    "app.profile.pic.heading": "YOUR PROFILE PICTURE",
    "app.profile.pic.change": "Click to change ...",
    "app.profile.pic.drop": "Drag your picture into this space ...",
    "app.profile.pic.delete": "Delete profile picture",
    "app.profile.pic.safe": "Save profile picture",
    "app.profile.add.adress": "Add address",
    "app.profile.all.adresses": "Your delivery addresses",
    "app.profile.adress.phone": "Phone:",
    "app.profile.adress.mail": "Email:",
    "app.profile.adress.settings": "Edit",
    "app.profile.adress.remove": "Remove",
    "app.profile.adress.change": "Edit address",
    "app.profile.adress.new": "New address",
    "app.profile.adress.changes.safe": "Save changes",
    "app.profile.adress.new.safe": "Save new address",
    "app.profile.login.heading": "Your login data",
    "app.profile.login.current.pw": "Current password",
    "app.profile.login.new.pw": "New password",
    "app.profile.login.new.pw.same": "Repeat new password",
    "app.profile.login.new.pw.safe": "Save new password",
    "app.safe.data": "Save data",
    "app.personal.data": "Personal data",
    "app.sign.up.complete": "Registration - please complete data",
    "app.sign.up.check.data": "In the last registration step, you can view, check and also correct your personal data. If you make changes to your first and last name, please contact our support team. Please also change your password again here.",
    "app.new.password_short": "New password",
    "app.new.password": "Your new password",
    "app.new.password_save": "Save new password",
    "app.new.password.repeat": "Please repeat your password",
    "app.award.code": "Reward code",
    "app.award.code.placeholder": "Code",
    "app.award.code.redeem": "Redeem",
    "app.exclusive.award.collection": "YOUR EXCLUSIVE REWARD SELECTION",
    "app.code.invalid": "Code not valid.",
    "app.forgot.password": "Forgotten your password?",
    "app.promo.booster": "PROMO-BOOSTER",
    "app.code.collect": "COLLECT POINTS WITH PROMO CODE",
    "app.welcome.at.company": "Welcome to the Sample Company Reward Party!",
    "app.code.collect.redeem": "You have purchased a promotional product?! Great, then you can redeem the promotion code (or several) right here. The corresponding points will automatically be credited to your points account. Afterwards, you can choose the reward of your choice to your heart's content.",
    "app.register.via.code": "To register via promo code",
    "app.code.to.sign.up": "Enter your promo code here to create an account in the XY Promocode Portal.",
    "app.code": "Promocode",
    "app.code.redeem": "Redeem code",
    "app.already.customer": "I am already a customer",
    "app.ranking": "RANKING - MY PLACEMENT",
    "app.belong.to.group": "You belong to the group",
    "app.rank": "Rank",
    "app.name": "Name",
    "app.rank.points": "Ranking points",
    "app.evaluation.criteria": "Ranking criteria",
    "app.your.current.rank": "Your current rank",
    "app.show.rank": "Show ranking",
    "app.news.no.heading": "No news available",
    "app.news.no.button": "Go to the rewards",
    "app.profile.language": "Language",

    //Salesmarathon
    "app.salesmarathon.goals": "Target",
    "app.salesmarathon.profit": "Bonus",
    "app.salesmarathon.lets.go": "Let's go, reach your goal!",
    "app.salesmarathon.currency1": "per",
    "app.salesmarathon.currency2": "revenue",

    // Salestriathlon
    "app.salestriathlon.month.heading": "Your monthly targets",
    "app.salestriathlon.goals.heading": "Your goal achievement",
    "app.salestriathlon.all.goals.heading": "Your overall target of the promotion",
    "app.salestriathlon.all.goals.text": "If you don't reach a monthly goal, you can still win full points if you reach your overall goal (sum of all months) at the end of the promotion. On that note: GIVE IT ALL YOU GOT!",
    "app.salestriathlon.finish.goals.heading": "Your overall goal achievement",
    "app.salestriathlon.your.reward": "Your reward",
    "app.salestriathlon.first.month": "First month",
    "app.salestriathlon.second.month": "Second month",
    "app.salestriathlon.third.month": "Third month",
    "app.salestriathlon.goals.contracts1": "Sign",
    "app.salestriathlon.goals.contracts2": "contracts!",
    "app.salestriathlon.goals.sales1": "Generate",
    "app.salestriathlon.goals.sales2": "Sales",

    //404
    "page.404.headline": "OH NO…",
    "page.404.subline": "Something is not working.",
    "page.404.info": "Either there was a terrible error or the page does not exist. But don't worry - we will help you!",
    "page.404.action.home": "To the homepage",
    "page.404.action.contact": "Contact us",
    "page.404.domain.headline": "Vielleicht vertippt?",
    "page.404.domain.info": "Please check the URL you entered. It should read something like this:",
  },

  // ===== FR =====
  "fr": {
    // Login
    "login.h1": "Bienvenue",
    "login.h2.subline": "FORD HEROSPLACE",
    "login.h2.login": "INSCRIPTION",
    "login.label.username": "E-Mail *",
    "login.input.username": "E-Mail",
    "login.label.password": "Mot de passe",
    "login.input.password": "Mot de passe",
    "login.button": "Login",
    "login.forgotten.password": "Mot de passe oublié",
    "login.error.info": "Erreur. Veuillez vérifier les données ou contacter le support",
    "login.new.password": " Entrez-en un nouveau",
    "login.contact.support": "Erreur de login ? Vérifiez vos données d'accès que vous avez reçues par e-mail.",
    "login.service.mail": "service@salesattack.de",
    "login.data.protection": "Protection des données",
    "login.imprint": "Imprimer",
    "login.contact": "Contact",
    "login.password_policy": "Entrez un nouveau mot de passe. Celui-ci doit contenir au moins 8 caractères, majuscules et minuscules, et au moins un chiffre.",
    "login.error.mail": "Erreur - L'adresse e-mail est manquante.",
    "login.error.password_policy": "Le mot de passe sélectionné n'est pas conforme aux règles de sécurité. Le mot de passe doit être composé d'au moins 8 caractères et contenir des lettres majuscules et minuscules et au moins un chiffre.",
    "login.error.password_mismatch":"Mots de passe non identiques!",
    "login.error.password_is_empty":"Erreur - Mot de passe manquant ",
    "login.password_reset.headline":"Réinitialiser le mot de passe",
    "login.password_reset.description": "Vous avez ici la possibilité de réinitialiser votre mot de passe. Lorsque vous confirmerez votre adresse e-mail, il vous sera demandé de saisir un nouveau mot de passe.",
    "login.password_reset.email_success": "Nous vous avons envoyé un lien pour changer votre mot de passe par e-mail.",
    "login.password_reset.success": "Super ! Le mot de passe a été réinitialisé avec succès.",
    "login.userdata.headline": "Données",
    "login.tos.headline": "Conditions de participation",
    "login.credentials.error": "L'adresse e-mail ou le mot de passe est incorrect.",

    // Global
    "global.close.info": " Fermer l'info",
    "global.mandatory.fields": "* Champs obligatoires",
    "global.open.info": "Plus d'informations",
    "global.rules": "Les règles du jeu",
    "global.rules.short": "Les règles du jeu",
    "global.points.account": "Compte de points",
    "global.award.overview": "Recompense",
    "global.recommendation": "Recommandations",
    "global.loading": "Update…",
    "global.your.cart": "Panier de commande",
    "global.your.order": "Commande",
    "global.less.info": "Moins d'info",
    "global.back_to_login": "Login",
    "global.next": "Suivant",
    "global.account.heading": "Pointage",
    "global.account.source": "Source",
    "global.account.date": "Date",
    "global.account.desc": "Description",
    "global.account.points": "Points",
    "global.account.sum": "Total de la période",
    "global.account.old.points": "Balance des comptes act.",
    "globale.change.products": "Recompense",
    "global.order.view": "Aperçu de la commande",
    "global.order.button.view": "Aperçu de la commande",
    "global.back.to.homepage": "Retour à la page d'accuei",
    "global.confirmation.heading": "Merci beaucoup, nous avons reçu la commande",
    "global.confirmation.text": "Vous recevrez une confirmation de commande par e-mail dans les prochaines minutes. Si vous avez des questions concernant votre commande, veuillez vérifier le statut de votre commande sous Menu / Statut de la commande. Vous pouvez y vérifier à tout moment l'état actuel de la livraison de votre commande. Amusez-vous avec votre prime.",
    "global.no.orders": "Pas de commande disponible",
    "global.switch.to.products": "Recompense",
    "global.wishlist.page.hint": "Avec l'aide de la liste de souhaits, vous pouvez économiser des recompense de façon permanente. Pour ce faire, placez l'incitation sur la liste de souhaits",
    "global.wishlist.page.remove.all": "Supprimer",
    "global.wishlist.page.add.all": "Ajouter tous les articles au panier",
    "global.wishlist.page.empty": "Liste de souhaits vide",
    "global.wishlist.page.button.cart": "Retour au panier d'achat",
    "global.countries.germany": "Allemagne",
    "global.countries.belgium": "Belgique",
    "global.countries.denmark": "Danemark",
    "global.countries.finnland": "Finnland",
    "global.countries.france": "Francia",
    "global.countries.uk": "Grande Bretagne",
    "global.countries.italy": "Italie",
    "global.countries.luxembourg": "Luxembourg",
    "global.countries.netherlands": "Pays-Bas",
    "global.countries.norway": "Norvège",
    "global.countries.austria": "Autriche",
    "global.countries.poland": "Pologne",
    "global.countries.romania": "Roumanie",
    "global.countries.sweden": "la Suède",
    "global.countries.switzerland": "Suisse",
    "global.countries.czech": "République Tchèque",
    "global.countries.hungary": "Hongrie",
    "global.countries.usa": "Etats-Unis",
    "global.back.to.login": "Retour connexion",
    "global.jackpotHeading": "Budget-total",
    "language.profile.de": "Allemand",
    "language.profile.en": "Anglais",
    "language.profile.fr": "Français",
    "language.profile.it": "Italien",

    // CART
    "cart.variant.label": "Variant: ",
    "cart.no.orders.button": "Ou vers la page de contact",
    "cart.no.orders.error.hint": "Veuillez réessayer.",
    "cart.wishlist.button.add": " Liste de souhaits",
    "cart.button.product.remove": "Supprimer",
    "cart.product.available": "Article immédiatement disponible",
    "cart.product.soon.available": "L'article est disponible à partir de ",
    "cart.popup.sum": "Total: ",
    "cart.popup.first": "Panier de commande",
    "cart.popup.second": "Articles",
    "cart.credit.box.text1": "Tu manques toujours",
    "cart.credit.box.text2": "points. Vous pouvez acheter les points manquants pour cette commande pour",
    "cart.credit.box.text3": "€ par Paypal",
    "cart.bank.recent": "Balance des comptes",
    "cart.credit.box.shipping": "Frais d'expédition et de douane incl.",
    "cart.credit.box.buttun.send": "A la commande",
    "cart.credit.box.button.more": "Plus de recompense",

    // CHECKOUT
    "checkout.form.adress": "Adresse de livraison choisie",
    "checkout.adress.button.change": "Modifier l'adresse",
    "checkout.adress.button.safe": "Enregistrer la nouvelle adresse",
    "checkout.adress.button.cancel": "Avorter",
    "checkout.choose.adress": "Choisissez votre adresse",
    "checkout.choose.adress.button.send": "Envoyer à cette adresse",
    "checkout.choose.adress.button.new": "Ajouter une nouvelle adresse",
    "checkout.qty.label": "Numéro",
    "checkout.qty.products": "Nombre d'objets: ",
    "checkout.hint.check": "Vérifiez la commande et confirmez en cliquant sur Envoyer la commande",
    "checkout.overview.adress": "Adresse de livraison",
    "checkout.mail.check": "Vous recevrez une confirmation par e-mail à l'adresse suivante: ",

    "checkout.back.to.cart": "Retour au panier d'achat",
    "checkout.change.adress.button": "Modifier l'adresse de livraison",
    "checkout.agb.check1": "J'accepte les ",
    "checkout.agb.check2": "conditions de participation",
    "checkout.agb.check3": " de Buben & Mädchen GmbH.",
    "checkout.load.points.button": "Rechargez les points",
    "checkout.contact.button": "Pour contacter le formulaire",
    "checkout.confirm.order.button": "Envoyer la commande",
    "checkout.no.orders.possible": "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.no.orders.hint": "Bitte beachten Sie, dass aktuell keine Prämienbestellungen möglich sind. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.modal.no.points": "Points manquants",
    "checkout.modal.currency": "€",
    "checkout.modal.currency.long": "EUR",
    "checkout.modal.payment.check": "Co-paiement complet",
    "checkout.modal.transaction.success": "Transaction réussie, ID",
    "checkout.modal.close.button": "Conclure",
    "checkout.modal.step1": "Acheter des points",
    "checkout.modal.step2": "Mode de paiement",
    "checkout.modal.step3": "La transaction a réussi",
    "checkout.breadcrumb.button1": "L'adresse",
    "checkout.breadcrumb.button2": "Aperçu",
    "checkout.breadcrumb.button3": "Confirmation",
    "checkout.order.no": "N° de commande",
    "checkout.order.date": "Date de commande",
    "checkout.order.show": "Voir l'ordre",

    // PROFILE
    "profile.page.heading": "Données personnelles",
    "profile.page.pw.no.empty": "Erreur - Mot de passe manquant ",
    "profile.page.pw.not.same": "Mot de passe faux",
    "profile.page.pw.min.txt": "Le mot de passe sélectionné n'est pas conforme aux règles de sécurité. Le mot de passe doit être composé d'au moins 8 caractères et contenir des lettres majuscules et minuscules et au moins un chiffre.",

    // CONTACT
    "contact.page.choose": "Veuillez choisir:",
    "contact.page.help1": "Question générale",
    "contact.page.help2": "Questions sur les critères de pointage / la comptabilisation des points / les règles du jeu",
    "contact.page.help3": "Questions sur les commandes et les primes",
    "contact.page.help3.special": "Questions sur les commandes et les primes",
    "contact.page.heading": "Sélectionnez le motif de la demande",
    "contact.page.reason": "Motif du contact",
    "contact.page.message": "Message",
    "contact.page.send": "Envoyer",
    "contact.page.info": "Écrivez-nous un e-mail. Nous sommes à votre disposition du lundi au vendredi de 9h00 à 18h00",

    // MENU
    "menu.main.categories": "Catégories principales",
    "menu.highlights": "Highlights",
    "menu.all.novelties": "Afficher toutes les actualités",
    "menu.award.category": "Icentive category",
    "menu.sub.categories": "Sub Category",
    "menu.welcome.text": "Hi!",
    "menu.edit.profile": "Profil",
    "menu.conditions.participation": "Conditions de participation",
    "menu.terms.delivery": "Conditions de livraison",
    "menu.contact": "Contact",
    "menu.logout": "Logout",
    "menu.home": "Home",
    "menu.my.contracts": "Contracts",
    "menu.points.account": "Compte de points",
    "menu.shopping.cart": "Panier de commande",
    "menu.order.status": "Statut de la commande",
    "menu.wishlist": "Liste de souhaits",
    "menu.news": "News",
    "menu.award.shop": "Shop",
    "menu.search": "Recherche",

    // SHOP
    "shop.delivery1.msg1": "Article livrable de suite ",
    "shop.delivery1.msg1.day": " Semaines",
    "shop.delivery2.msg2": "Article livrable de suite ",
    "shop.delivery2.msg2.day": " Jours ouvrables",

    "global.empty.table": "Aucune entrée disponible",
    "global.cart.empty": "Votre panier est vide",
    "global.wishlist.button": "Voir ma liste de souhaits",
    "global.button.go.to.contact": "Pour contacter le formulaire",
    "global.key.new": "NEW",
    "global.something.went.wrong": "Oups, quelque chose s'est mal passé.",
    "global.no.orders.in.time": "Il n'y a pas de commandes dans la période.",
    "global.accordion.orders.heading": "Filtre de période",
    "global.flatpicker.start": "de",
    "global.flatpicker.end": "jusqu'à",
    "global.flatpicker.date": "Choisissez la date..",
    "global.flatpicker.button": "Recharger",
    "global.profile.page.heading": "Votre profil",
    "cart.no.orders": "Vous ne pouvez actuellement pas commander d'articles bonus car vous êtes soumis à un blocage de commande. Si vous avez des questions à ce sujet, veuillez contacter votre personne de contact responsable.",
    "cart.hint": "Veuillez noter qu'aucune commande de récompenses n'est actuellement possible. Si vous avez des questions à ce sujet, veuillez contacter votre personne de contact responsable.",
    "cart.qty": "Numéro: ",
    "cart.no.available": "L'article n'est pas disponible !",
    "cart.wishlist.button.remove": "Supprimer de la liste de souhaits",
    "cart.order.heading": "Statut de la commande",
    "cart.track.order.button": "Suivi de l'expédition",
    "cart.popup.delivery": "Livraison",
    "profile.page.pw.now": "Veuillez saisir votre mot de passe actuel",
    "contact.page.success": "Le message a été envoyé avec succès!",
    "contact.page.message.placeholder": "Mesdames et Messieurs..",
    "contact.page.order.nr": "Numéro de commande (TB.xxx) ou numéro d'article (BM-xxx)",
    "menu.ratings": "Les critères de pointage",
    "menu.ranking": "Classement",
    "app.new": "Nouveau",
    "app.read.on": "continuer la lecture",
    "app.read.all.news": "Lire toutes les actualités",
    "app.customer": "Concessionnaire",
    "app.customer.placeholder": "Concessionnaire",
    "app.customer.nr": "N° de concessionaire",
    "app.profile.pic.heading": "Image de profil",
    "app.profile.pic.change": "Cliquez pour changer ...",
    "app.profile.pic.drop": "Faites glisser votre image dans cette zone...",
    "app.profile.pic.delete": "Supprimer la photo de profil",
    "app.profile.pic.safe": "Enregistrer la photo de profil",
    "app.profile.adress.new.safe": "Enregistrer la nouvelle adresse",
    "app.award.code.redeem": "Racheter",

    // APP
    "app.more.info": " + Infos",
    "app.less.info": " - Infos",
    "app.currency": "Euro",
    "app.currency_points": "Unités",
    "app.profit": "Avantages",
    "app.points": "PTS",
    "app.contracts": "Contracts",
    "app.contracts.number": "Points du contrat",
    "app.days": "Jo",
    "app.hours": "Hrs",
    "app.mins": "Min",
    "app.award.news": "Nouveaux hits",
    "app.award.highlights": "Incentive Highlights",
    "app.video.no.support": "Votre browser ne supporte pas la vidéo. Veuillez actualiser votre browser.",
    "app.campaign.end": "FORD Herosplace Timer",
    "app.sales.performance": "Scoreboard",
    "app.sales.national": "National",
    "app.sales.per.contract": "Points du contrat",
    "app.sales.status": "Score",
    "app.your.place": "Position",
    "app.current.level": "Incentive Level",
    "app.win.level": "Niveaux gagnants",
    "app.place": "Position",
    "app.overall.ranking": "Au classement général",
    "app.contracts.closed": "Points du contrat",
    "app.herosplace.ranking": "HEROSPLACE RANKING",
    "app.back.win.level": "Niveaux gagnants",
    "app.to.overall.ranking": "Au classement général",
    "app.award.search": "Recherche de récompense",
    "app.award.search.keyword": "Terme de recherche",
    "app.award.search.points.from": "Points min",
    "app.award.search.points.to": "Points max",
    "app.award.search.start": "Lancer la recherche",
    "app.point":  "Points",
    "app.currency.icon": "€",
    "app.information": "Informations",
    "app.plus": "PLUS",
    "app.back.to.overview": "Retour à l'aperçu",
    "app.category": "Catégories",
    "app.award.variant": "Variante de bonus",
    "app.put.in.cart": "Dans le panier",
    "app.award.despcription": "Description du prix",
    "app.main.category": "Catégorie principale",
    "app.sub.category": "Sous-catégorie",
    "app.choose.main.category": "Veuillez sélectionner une catégorie principale.",
    "app.shop.search": "Chercher",
    "app.shop.sort.for": "Trier par",
    "app.shop.sort.for.newest": "Dernières récompenses",
    "app.shop.sort.for.az": "Alphabétiquement A-Z",
    "app.shop.sort.for.za": "Alphabétiquement Z-A",
    "app.password.not.match": "Mot de passe faux",
    "app.sign.up.step.one": "Step 1/2",
    "app.sign.up.declare": " J'accepte ",
    "app.sign.up.agree": " de Buben & Mädchen GmbH.",
    "app.sign.up.just_agree": " ",
    "app.continue": "Suivant",
    "app.privacy.policy": "Réglementation de protection des données",
    "app.privacy.disclaimer": "J'accepte que mes données personnelles soient traitées par le bureau indiqué dans la réglementation sur la protection des données dans le cadre de ma participation au programme susmentionné aux fins de la participation à la incitation. Je suis conscient que seules les données personnelles nécessaires à la participation à l'incitation seront traitées. Les données correspondantes seront fournies par moi-même. Je suis également conscient que je peux révoquer mon consentement à tout moment en faisant une simple déclaration au bureau indiqué dans le réglementation sur la protection des données. Je dispose également des droits prévus au chapitre trois du DS-GVO, à savoir le droit à l'information, à la rectification, à l'effacement, à la portabilité des données, à la limitation du traitement, à l'objection et à l'introduction d'une plainte auprès d'une autorité de contrôle. ",
    "app.sign.up.step.two": "Step 2/2",
    "app.company": "Enterprise",
    "app.company.placeholder": "Garage Xyz",
    "app.salutation": "Titre",
    "app.mr": "M.",
    "app.ms": "Mme.",
    "app.dr": "Dr",
    "app.firstname": "Prénom",
    "app.lastname": "Nom",
    "app.firstname.placeholder": "Max",
    "app.lastname.placeholder": "Mustermann",
    "app.street": "Rue et n° de maison",
    "app.street.placeholder": "Musterstraße",
    "app.plz": "CP*",
    "app.plz.placeholder": "12345",
    "app.city": "Ville*",
    "app.city.placeholder": "Ville",
    "app.country": "Pays",
    "app.country.placeholder": "Pays",
    "app.birthday": "Date de naissance",
    "app.birthday.placeholder": "01.03.1960",
    "app.email_short": "E-Mail",
    "app.email": "E-Mail adress*",
    "app.email_your": "E-Mail adress*",
    "app.email.placeholder": "m.mustermann@muster.ch",
    "app.phone": "Tél.",
    "app.phone.placeholder": "079 000 555",
    "app.handy": "Numéro de portable",
    "app.handy.placeholder": "079 000 555",
    "app.customer.nr.placeholder": "123-456-789",
    "app.agree.checkbox": "J'accepte de recevoir régulièrement des informations sur le programme.",
    "app.profile.add.adress": " Ajouter une nouvelle adresse",
    "app.profile.all.adresses": "Vos adresses de livraison",
    "app.profile.adress.phone": "Tél.:",
    "app.profile.adress.mail": "E-Mail:",
    "app.profile.adress.settings": "Modifier",
    "app.profile.adress.remove": "Supprimer",
    "app.profile.adress.change": "Modifier l'adresse",
    "app.profile.adress.new": "Ajouter une nouvelle adresse",
    "app.profile.adress.changes.safe": "Confirmez les données",
    "app.profile.login.heading": "Données Login",
    "app.profile.login.current.pw": "Mot de passe act.",
    "app.profile.login.new.pw": " Nouveau mot de passe",
    "app.profile.login.new.pw.same": "Veuillez répéter votre mot de passe",
    "app.profile.login.new.pw.safe": "Enregistrez le nouveau mot de passe",
    "app.safe.data": "Confirmez les données",
    "app.personal.data": "Données personnelles",
    "app.sign.up.complete": "Inscription - veuillez compléter les données",
    "app.sign.up.check.data": "Dernière étape : vous pouvez ici consulter, vérifier et aussi corriger vos données. Si vous changez de prénom ou de nom, veuillez contacter notre équipe de support. Veuillez également changer à nouveau votre mot de passe ici.",
    "app.new.password_short": " Nouveau mot de passe",
    "app.new.password": " Votre nouveau mot de passe",
    "app.new.password_save": "Enregistrez le nouveau mot de passe",
    "app.new.password.repeat": "Veuillez répéter votre mot de passe",
    "app.award.code": "Code de récompense",
    "app.award.code.placeholder": "Code",
    "app.forgot.password": "Mot de passe oublié",
    "app.news.no.heading": "Pas de nouvelles actualités",
    "app.news.no.button": "Retour à la liste des récompenses",
    "app.profile.language": "Langue",

    // Salestriathlon
    "app.salestriathlon.month.heading": "Deine Monatsziele",
    "app.salestriathlon.goals.heading": "Deine Zielerreichung",
    "app.salestriathlon.all.goals.heading": "Dein Gesamtziel der Aktion",
    "app.salestriathlon.all.goals.text": "Solltest Du ein Monatsziel nicht erreichen, kannst Du trotzdem noch die volle Punktzahl gewinnen, wenn Du am Ende der Aktion Dein Gesamtziel (Summe aller Monate) erreichst. In diesem Sinne: GIB ALLES!",
    "app.salestriathlon.finish.goals.heading": "Deine Gesamtziel-Erreichung",
    "app.salestriathlon.your.reward": "Deine Belohnung",
    "app.salestriathlon.first.month": "Erster Monat",
    "app.salestriathlon.second.month": "Zweiter Monat",
    "app.salestriathlon.third.month": "Dritter Monat",
    "app.salestriathlon.goals.contracts1": "Schließe",
    "app.salestriathlon.goals.contracts2": "Verträge ab!",
    "app.salestriathlon.goals.sales1": "Generiere",
    "app.salestriathlon.goals.sales2": "Umsatz",

    //404
    "page.404.headline": "OH NO ...",
    "page.404.subline": "Une erreur.",
    "page.404.info": "Soit il y a eu une erreur, soit la page n'existe pas. Mais ne vous inquiétez pas, nous vous aiderons!",
    "page.404.action.home": "Page d'accueil",
    "page.404.action.contact": "Contact",
    "page.404.domain.headline": "Peut-être une erreur de frappe?",
    "page.404.domain.info": "Veuillez vérifier l'URL que vous avez saisie. Il devrait se lire approximativement comme suit",
  },

  // ===== FR =====
  "it": {
    // Login
    "login.h1": "Benvenuto",
    "login.h2.subline": "Nel nostro portale clienti",
    "login.h2.login": "Accesso",
    "login.label.username": "E-Mail *",
    "login.input.username": "E-Mail",
    "login.label.password": "Password *",
    "login.input.password": "Password",
    "login.button": "Accesso",
    "login.forgotten.password": "Hai dimenticato la password?",
    "login.error.info": "Si è verificato un errore. Controlla i dati inseriti o contatta il nostro supporto.",
    "login.new.password": "Impostane una nuova.",
    "login.contact.support": "Non riesci ad accedere? Controlla i dati di accesso che hai ricevuto per email.",
    "login.service.mail": "service@incentivearena.de",
    "login.data.protection": "Protezione dati",
    "login.imprint": "Note Legali",
    "login.contact": "Contatto",
    "login.password_policy": "Inserisci una nuova password. Deve contenere almeno 8 caratteri, maiuscole e minuscole e almeno un numero.",
    "login.error.mail": "Il campo con l’indirizzo email non deve essere vuoto",
    "login.error.password_policy": "La password scelta non è conforme alle norme di sicurezza. La password deve contenere almeno 8 caratteri, lettere maiuscole e minuscole e almeno un numero.",
    "login.error.password_mismatch": "Le password non corrispondono!",
    "login.error.password_is_empty": "Il campo della password non deve essere vuoto",
    "login.password_reset.headline": "Reimposta password",
    "login.password_reset.description": "Puoi reimpostare qui la tua password. Non appena confermi il tuo indirizzo email, ti verrà chiesto di inserire una nuova password.",
    "login.password_reset.email_success": "Ti abbiamo inviato un link per modificare la password al tuo indirizzo email.",
    "login.password_reset.success": "Fantastico, ha funzionato! La password è stata reimpostata con successo.",
    "login.userdata.headline": "Compilazione dei dati",
    "login.tos.headline": "Condizioni di partecipazione",
    "login.credentials.error": "L'indirizzo e-mail o la password non sono corretti.",

    // Global
    "global.close.info": "Chiudi informazioni",
    "global.mandatory.fields": "* Campi richiesti",
    "global.open.info": "Ulteriori informazioni",
    "global.rules": "Regolamento",
    "global.rules.short": "regolamento",
    "global.points.account": "Saldo del conto punti",
    "global.award.overview": "Continua",
    "global.recommendation": "Le nostre raccomandazioni",
    "global.loading": "Caricamento in corso...",
    "global.your.cart": "Il tuo carrello della spesa",
    "global.your.order": "Il tuo ordine",
    "global.less.info": "Meno informazioni",
    "global.back_to_login": "Torna all’accesso",
    "global.next": "Avanti",
    "global.account.heading": "Punteggio",
    "global.account.source": "Source",
    "global.account.date": "Data",
    "global.account.desc": "Descrizione",
    "global.account.points": "Punti",
    "global.account.sum": "Totale nel periodo",
    "global.account.old.points": "Saldo attuale",
    "global.empty.table": "Nessuna voce disponibile",
    "global.change.products": "Vai ai premi",
    "global.cart.empty": "Il tuo carrello della spesa è vuoto.",
    "global.wishlist.button": "Mostra la mia lista dei desideri",
    "global.button.go.to.contact": "Al modulo di contatto",
    "global.badge.neuheit": "Novità",
    "global.badge.highlight": "In evidenza",
    "global.badge.luxus": "Luxury premium",
    "global.key.new": "NEW",
    "global.order.view": "Panoramica degli ordini",
    "global.order.button.view": "Vai alla panoramica degli ordini",
    "global.back.to.homepage": "Torna alla pagina iniziale",
    "global.something.went.wrong": "Oops, si è verificato un errore.",
    "global.confirmation.heading": "Grazie, abbiamo ricevuto il tuo ordine.",
    "global.confirmation.text": "Nei prossimi minuti riceverai una conferma d’ordine all’indirizzo email che hai fornito. Se hai domande sul tuo ordine, controlla il suo stato in “Account/Stato dell’ordine”. Puoi vedere lo stato attuale della spedizione del tuo ordine in qualsiasi momento. Divertiti con il nostro Premium.",
    "global.no.orders.in.time": "Non ci sono ordini disponibili nel periodo.",
    "global.no.orders": "Nessun ordine disponibile.",
    "global.switch.to.products": "Passa ai premi",
    "global.accordion.orders.heading": "Filtro periodo",
    "global.flatpicker.start": "da",
    "global.flatpicker.end": "a",
    "global.flatpicker.date": "Seleziona data.",
    "global.flatpicker.button": "Ricarica",
    "global.wishlist.page.hint": "Puoi usare la tua lista dei desideri per salvare in modo permanente le richieste. Per farlo, sposta il premio nella lista dei desideri. Non appena avrai abbastanza punti per fare un ordine, sarai automaticamente informato via email e potrai facilmente trasferire gli articoli della lista dei desideri in un ordine.",
    "global.wishlist.page.remove.all": "Rimuovi tutti gli articoli",
    "global.wishlist.page.add.all": "Aggiungi tutti gli articoli al carrello della spesa",
    "global.wishlist.page.empty": "La tua lista dei desideri è vuota.",
    "global.wishlist.page.button.cart": "Vai al carrello della spesa",
    "global.profile.page.heading": "Il tuo profilo",
    "global.homepage.start": "Iniziamo!",
    "global.countries.germany": "Germania",
    "global.countries.belgium": "Belgio",
    "global.countries.denmark": "Danimarca",
    "global.countries.finnland": "Finnland",
    "global.countries.france": "la France",
    "global.countries.uk": "Gran Bretagna",
    "global.countries.italy": "Italia",
    "global.countries.luxembourg": "Lussemburgo",
    "global.countries.netherlands": "Olanda",
    "global.countries.norway": "Norvegia",
    "global.countries.austria": "Austria",
    "global.countries.poland": "Polonia",
    "global.countries.romania": "Romania",
    "global.countries.sweden": "Svezia",
    "global.countries.switzerland": "Svizzera",
    "global.countries.czech": "Repubblica Ceca",
    "global.countries.hungary": "Ungheria",
    "global.countries.usa": "Stati Uniti d’America",
    "global.back.to.login": "Torna al login",
    "global.jackpotHeading": "Budget-totale",
    "language.profile.de": "Tedesco",
    "language.profile.en": "Inglese",
    "language.profile.fr": "Francese",
    "language.profile.it": "Italiano",

    // CART
    "cart.no.orders": "Non puoi ordinare nessun articolo premium al momento perché sei soggetto a un blocco degli ordini. Se ha domande al riguardo, contatta la persona di riferimento.",
    "cart.no.orders.button": "Oppure alla pagina dei contatti",
    "cart.no.orders.error.hint": "Si prega di riprovare.",
    "cart.hint": "Nota che attualmente non è possibile ordinare articoli Premium. In caso di domande al riguardo, contatta il referente competente.",
    "cart.qty": "Quantità: ",
    "cart.variant.label": "Variante: ",
    "cart.no.available": "L’articolo non è disponibile!",
    "cart.wishlist.button.remove": "Rimuovi dalla lista dei desideri",
    "cart.wishlist.button.add": "Aggiungi alla lista dei desideri",
    "cart.order.heading": "Stato dell’ordine",
    "cart.button.product.remove": "Rimuovi",
    "cart.track.order.button": "Traccia spedizione",
    "cart.product.available": "Articolo disponibile immediatamente",
    "cart.product.soon.available": "Articolo disponibile da ",
    "cart.popup.sum": "Importo totale:",
    "cart.popup.delivery": "Consegna",
    "cart.popup.first": "Carrello della spesa",
    "cart.popup.second": "Articolo",
    "cart.credit.box.text1": "Per completare l’ordine hai ancora bisogno di",
    "cart.credit.box.text2": "punti. Puoi acquistare i punti mancanti per quest’ordine per",
    "cart.credit.box.text3": "Euro tramite Paypal per acquistare ulteriori punti",
    "cart.bank.recent": "Saldo del conto",
    "cart.credit.box.shipping": "Spedizione e costi di consegna inclusi",
    "cart.credit.box.buttun.send": "All’ordine",
    "cart.credit.box.button.more": "Altri premi",

    // CHECKOUT
    "checkout.form.adress": "Indirizzo di consegna selezionato",
    "checkout.adress.button.change": "Modifica indirizzo",
    "checkout.adress.button.safe": "Salva indirizzo",
    "checkout.adress.button.cancel": "Annulla",
    "checkout.choose.adress": "Seleziona l’indirizzo",
    "checkout.choose.adress.button.send": "Spedisci a questo indirizzo",
    "checkout.choose.adress.button.new": "Aggiungi nuovo indirizzo",
    "checkout.qty.label": "Quantità",
    "checkout.qty.products": "Quantità di articoli: ",
    "checkout.hint.check": "Controlla l’ordine e confermalo facendo clic su Invia ordine",
    "checkout.overview.adress": "Indirizzo di consegna",
    "checkout.mail.check": "Riceverai una conferma via email al seguente indirizzo: ",
    "checkout.back.to.cart": "Torna al carrello della spesa",
    "checkout.change.adress.button": "Cambia indirizzo di consegna",
    "checkout.agb.check1": "Accetto le ",
    "checkout.agb.check2": "Condizioni di partecipazione",
    "checkout.agb.check3": " di Buben & Mädchen GmbH.",
    "checkout.load.points.button": "Ricarica punti",
    "checkout.contact.button": "Al modulo di contatto",
    "checkout.confirm.order.button": "Invia ordine",
    "checkout.no.orders.possible": "Non puoi ordinare nessun articolo Premium al momento perché sei soggetto a un blocco degli ordini. Se hai domande al riguardo, contatta la persona di riferimento responsabile.",
    "checkout.no.orders.hint": "Nota che attualmente non è possibile ordinare i premi. In caso di domande, contatta la persona di riferimento.",
    "checkout.modal.no.points": "Punti mancanti",
    "checkout.modal.currency": "€",
    "checkout.modal.currency.long": "EUR",
    "checkout.modal.payment.check": "Completa pagamento aggiuntivo",
    "checkout.modal.transaction.success": "Transazione andata a buon fine, ID",
    "checkout.modal.close.button": "Chiudi",
    "checkout.modal.step1": "Acquista punti",
    "checkout.modal.step2": "Metodo di pagamento",
    "checkout.modal.step3": "Transazione andata a buon fine",
    "checkout.breadcrumb.button1": "Indirizzo",
    "checkout.breadcrumb.button2": "Panoramica",
    "checkout.breadcrumb.button3": "Conferma",
    "checkout.order.no": "Numero d'ordine",
    "checkout.order.date": "Data dell'ordine",
    "checkout.order.show": "Visualizza ordine",

    // PROFILE
    "profile.page.heading": "I tuoi dati personali",
    "profile.page.pw.now": "Digita la password attuale",
    "profile.page.pw.no.empty": "Il campo della password non deve essere vuoto",
    "profile.page.pw.not.same": "Le password non corrispondono",
    "profile.page.pw.min.txt": "La password scelta non è conforme alle norme di sicurezza. La password deve essere di almeno 8 caratteri e contenere lettere maiuscole e minuscole e almeno un numero.",

    // CONTACT
    "contact.page.choose": "Si prega di selezionare:",
    "contact.page.success": "Messaggio inviato con successo!",
    "contact.page.help1": "Domande generali",
    "contact.page.help2": "Domande sui criteri di realizzazione/assegnazione dei punti/regole del gioco",
    "contact.page.help3": "Domande su ordini e premi",
    "contact.page.help3.special": "Domande su ordini e premi",
    "contact.page.heading": "Seleziona il motivo della tua richiesta.",
    "contact.page.reason": "Motivo del contatto",
    "contact.page.order.nr": "Numero d’ordine (TB.xxx) o numero articolo (BM-xxx)",
    "contact.page.message": "Il tuo messaggio",
    "contact.page.message.placeholder": "Egregio signore o gentile signora...",
    "contact.page.send": "Invia",
    "contact.page.info": "Inviaci un’email. Siamo a tua disposizione dal lunedì al venerdì, dalle 9:00 alle 18:00.",

    // MENU
    "menu.main.categories": "Categorie",
    "menu.highlights": "In evidenza",
    "menu.all.novelties": "Mostra tutte le novità",
    "menu.award.category": "Categoria di premio",
    "menu.sub.categories": "Sottocategorie",
    "menu.welcome.text": "Ciao",
    "menu.edit.profile": "Modifica profilo",
    "menu.conditions.participation": "Condizioni di partecipazione",
    "menu.terms.delivery": "Condizioni di fornitura",
    "menu.contact": "Contatto",
    "menu.logout": "Chiudi sessione",
    "menu.home": "Home",
    "menu.ratings": "Valutazioni",
    "menu.ranking": "Classificazione",
    "menu.my.contracts": "I miei contratti",
    "menu.sort.contracts": "Assegnazione contratto",
    "menu.winners.trip": "Premio per i vincitori",
    "menu.points.account": "Conto dei punti",
    "menu.shopping.cart": "Carrello",
    "menu.order.status": "Stato dell’ordine",
    "menu.wishlist": "Lista dei desideri",
    "menu.news": "Notizie",
    "menu.award.shop": "Negozio dei premi",
    "menu.gamerules": "Regolamento",
    "menu.search": "Ricerca",

    // SHOP
    "shop.delivery1.msg1": "Articolo disponibile entro ",
    "shop.delivery1.msg1.day": " settimane",
    "shop.delivery2.msg2": "Articolo disponibile tra ",
    "shop.delivery2.msg2.day": " giorni lavorativi",

    // APP
    "app.more.info": "+ informazioni",
    "app.less.info": "Meno informazioni",
    "app.currency": "Euro",
    "app.currency_points": "Unità",
    "app.sales": "Vendite",
    "app.profit": "Guadagno",
    "app.points": "PT",
    "app.contracts": "Contratti",
    "app.contracts.number": "Numero di punti per contratto",
    "app.contracts.number.previous": "Numero di punti per contratto nell’anno precedente",
    "app.sales.previous": "Vendite nell’anno precedente",
    "app.goals": "Target",
    "app.goals.target": "Il tuo target:",
    "app.days": "Giorni",
    "app.hours": "Ore",
    "app.mins": "Minuti",
    "app.award.news": "Novità Premium - già scoperte?",
    "app.award.highlights": "Premium in evidenza",
    "app.remaining.time": "Tempo restante al termine dell’azione",
    "app.your.performance": "La tua prestazione rispetto al target",
    "app.your.target": "Raggiungimento dell’obiettivo rispetto all’anno scorso",
    "app.your.target.reached": "Congratulazioni, hai raggiunto il tuo obiettivo! Grande performance, continua così!",
    "app.lets.go": "Coraggio, non hai ancora raggiunto ",
    "app.next.win": " il livello vincente successivo.",
    "app.attack": "All’attacco! Raggiungi i tuoi target",
    "app.goal": "Il tuo obiettivo: Essere meglio dell’anno scorso!",
    "app.you.achieved": "Sei già a questo punto:",
    "app.win.levels": "Livelli conquistati - Quello che puoi vincere",
    "app.video.no.support": "Il tuo browser non supporta il tag video. Aggiorna il browser.",
    "app.campaign.end": "La promozione Super Sales termina tra:",
    "app.sales.performance": "Le tue prestazioni di vendita",
    "app.sales.national": "Nazionale",
    "app.sales.per.contract": "Vendite per contratto",
    "app.sales.status": "La tua posizione",
    "app.your.place": "LA TUA POSIZIONE IN CLASSIFICA",
    "app.current.level": "Livello di profitto attuale",
    "app.win.level": "Livelli di profitto",
    "app.place": "Classifica",
    "app.overall.ranking": "Classifica generale",
    "app.sales.contract": "Fatturato dei contratti",
    "app.contracts.closed": "Punti per contratto",
    "app.herosplace.ranking": "CLASSIFICAZIONE HEROSPLACE",
    "app.back.win.level": "Torna ai livelli di profitto",
    "app.to.overall.ranking": "Alla classifica generale",
    "app.choose.challenge": "Premi - Seleziona una sfida",
    "app.challenge": "Sfida",
    "app.target.value": "Valore del target",
    "app.your.profit": "Il tuo profitto",
    "app.schaffeich": "Sì, posso farcela!",
    "app.this.challenge": "Scegli questa sfida",
    "app.personal.challenge": "La tua sfida personale",
    "app.target.achievement": "Raggiungimento dell’obiettivo",
    "app.actual.value": "IL VALORE",
    "app.target.value.challenge": "VALORE DELL’OBIETTIVO",
    "app.win.target.achievement": "Vinci se raggiungi il target",
    "app.promotional.period.ends": "Il periodo di promozione termina tra:",
    "app.time.choose.challenge": "È il momento di scegliere la sfida!",
    "app.save.challenge": "Salva sfida",
    "app.award.search": "ricerca Premium",
    "app.award.search.keyword": "Ricerca parola chiave",
    "app.award.search.points.from": "Punti da",
    "app.award.search.points.to": ".. a",
    "app.award.search.start": "Inizia ricerca",
    "app.costumer": "Clienti",
    "app.new.costumer.barometer": "Barometro nuovo cliente",
    "app.already.achieved": "Quello che hai già raggiunto",
    "app.acquired.new.customers": "Nuovi clienti acquisiti",
    "app.contracts.missing": "Contratti che mancano per il livello successivo.",
    "app.number.new.costumers": "Numero di nuovi clienti",
    "app.gain.points": "Punti vinti",
    "app.all.win.steps": "Tutti i livelli vinti",
    "app.available.games": "Giochi disponibili",
    "app.equals.closed.contracts": "Corrisponde ai contratti firmati",
    "app.have.fun": "Buon divertimento!",
    "app.points.bandit": "Punti Bandit",
    "app.bandit.info.klick": "Fai clic sui ",
    "app.bandit.info.game": "punti Bandit e gioca.",
    "app.points.safe": "Punti Vault",
    "app.point": "Punti",
    "app.points.safe.info": "Ogni volta che vinci dei punti, i punti Vault generali si riducono. La promozione termina quando i punti Vault scendono a zero.",
    "app.our.sales.target": "Il nostro obiettivo di fatturato",
    "app.until.the": "fino al ",
    "app.current.sales": "Fatturato attuale",
    "app.currency.icon": "€",
    "app.information": "Informazioni",
    "app.plus": "PLUS",
    "app.bonus.ammount": "Bonus per un importo di",
    "app.deadline": "Scadenza",
    "app.monthly.award.points": "Ogni mese ti accrediteremo dei punti bonus del valore di 44 €. Questo è un regalo completamente esentasse. Puoi usare i punti per scegliere il tuo premio personale tra più di 3.000 fantastici prodotti. Buon divertimento!",
    "app.my.points": "I MIEI PUNTI",
    "app.news.award.club": "Notizie dal Reward Club",
    "app.new": "NUOVO",
    "app.read.on": "leggi tutto",
    "app.read.all.news": "Leggi tutte le notizie",
    "app.jackpot.counter": "CONTATORE DEL JACKPOT",
    "app.still.in.jackpot": "Sono ancora nel jackpot",
    "app.your.point.status": "Il tuo punteggio",
    "app.back.to.overview": "Torna alla panoramica",
    "app.category": "Categoria",
    "app.award.variant": "Variante Premium",
    "app.put.in.cart": "Aggiungi al carrello della spesa",
    "app.award.despcription": "Descrizione Premium",
    "app.main.category": "Categoria principale",
    "app.sub.category": "Sottocategoria",
    "app.choose.main.category": "Seleziona una categoria principale.",
    "app.shop.search": "Ricerca",
    "app.shop.sort.for": "Ordina per",
    "app.shop.sort.for.newest": "Ultimi premi",
    "app.shop.sort.for.az": "Ordine alfabetico A-Z",
    "app.shop.sort.for.za": "Ordine alfabetico Z-A",
    "app.password.not.match": "Le password non corrispondono",
    "app.sign.up.step.one": "Fase 1/2",
    "app.sign.up.declare": "Accetto le ",
    "app.sign.up.agree": " di Buben & Mädchen GmbH ",
    "app.sign.up.just_agree": "",
    "app.continue": "Continua",
    "app.privacy.policy": "Condizioni di protezione dati",
    "app.privacy.disclaimer": "Acconsento al trattamento dei miei dati personali da parte dell’ufficio indicato nella normativa sulla protezione dei dati ai fini della mia partecipazione al suddetto programma e della partecipazione all’incentivo. Prendo atto che saranno trattati solo i dati personali necessari per la partecipazione all’incentivo. Fornirò personalmente tali dati. Sono anche consapevole di poter revocare il mio consenso in qualsiasi momento facendo una semplice dichiarazione all’ufficio indicato nel regolamento sulla protezione dei dati. Posso, inoltre, beneficiare dei diritti di cui al capitolo 3 del GDPR relativamente a informazione, correzione, cancellazione, portabilità dei dati, limitazione del trattamento ed opposizione e di presentare un reclamo a un'autorità di controllo.",
    "app.sign.up.step.two": "Fase 2/2",
    "app.company": "Società",
    "app.company.placeholder": "Società XY",
    "app.salutation": "Saluti",
    "app.mr": "Sig.",
    "app.ms": "Sig.ra.",
    "app.dr": "Dott.",
    "app.firstname": "Nome",
    "app.lastname": "Cognome",
    "app.firstname.placeholder": "Jon",
    "app.lastname.placeholder": "Doe",
    "app.street": "Via e numero civico",
    "app.street.placeholder": "Strada di esempio",
    "app.plz": "CODICE POSTALE*",
    "app.plz.placeholder": "12345",
    "app.city": "Città*",
    "app.city.placeholder": "Città",
    "app.country": "Nazione",
    "app.country.placeholder": "Nazione",
    "app.birthday": "Data di nascita",
    "app.birthday.placeholder": "21976",
    "app.email_short": "Email",
    "app.email": "Indirizzo email*",
    "app.email_your": "Il tuo indirizzo email*",
    "app.email.placeholder": "m.mustermann@muster.de",
    "app.phone": "Telefono",
    "app.phone.placeholder": "1234 567890",
    "app.handy": "Cellulare",
    "app.handy.placeholder": "1234 567890",
    "app.customer": "Rivenditori",
    "app.customer.placeholder": "Rivenditore",
    "app.customer.nr": "Numero rivenditore",
    "app.customer.nr.placeholder": "123-456-789",
    "app.agree.checkbox": "Accetto di ricevere regolarmente informazioni sul programma.",
    "app.profile.pic.heading": "IMMAGINE DEL PROFILO",
    "app.profile.pic.change": "Fai clic per cambiare...",
    "app.profile.pic.drop": "Trascina l’immagine in questo spazio...",
    "app.profile.pic.delete": "Elimina immagine del profilo",
    "app.profile.pic.safe": "Salva immagine del profilo",
    "app.profile.add.adress": "Aggiungi indirizzo",
    "app.profile.all.adresses": "Indirizzi di consegna",
    "app.profile.adress.phone": "Tel.:",
    "app.profile.adress.mail": "Email:",
    "app.profile.adress.settings": "Modifica",
    "app.profile.adress.remove": "Rimuovi",
    "app.profile.adress.change": "Modifica indirizzo",
    "app.profile.adress.new": "Nuovo indirizzo",
    "app.profile.adress.changes.safe": "Salva modifiche",
    "app.profile.adress.new.safe": "Salva nuovo indirizzo",
    "app.profile.login.heading": "Dati di accesso",
    "app.profile.login.current.pw": "Password attuale",
    "app.profile.login.new.pw": "Nuova password",
    "app.profile.login.new.pw.same": "Ripeti nuova password",
    "app.profile.login.new.pw.safe": "Salva nuova password",
    "app.safe.data": "Salva dati",
    "app.personal.data": "Dati personali",
    "app.sign.up.complete": "Registrazione - inserisci i dati",
    "app.sign.up.check.data": "Nell’ultimo passo della registrazione, puoi visualizzare, controllare e anche correggere i tuoi dati personali. In caso di modifiche a nome e cognome, contatta il nostro team di supporto. Modifica di nuovo la password qui.",
    "app.new.password_short": "Nuova password",
    "app.new.password": "La tua nuova password",
    "app.new.password_save": "Salva nuova password",
    "app.new.password.repeat": "Ripeti la password",
    "app.award.code": "Codice premio",
    "app.award.code.placeholder": "Codice",
    "app.award.code.redeem": "Riscatta",
    "app.exclusive.award.collection": "LA TUA SELEZIONE ESCLUSIVA DI PREMI",
    "app.code.invalid": "Codice non valido.",
    "app.forgot.password": "Password dimenticata?",
    "app.promo.booster": "PROMO-BOOSTER",
    "app.code.collect": "RACCOGLI I PUNTI CON IL CODICE PROMOZIONE",
    "app.welcome.at.company": "Benvenuti al milkrite | InterPuls Reward Party!",
    "app.code.collect.redeem": "Hai acquistato un prodotto promozionale?! Ottimo, ora puoi riscattare il codice promozionale (o più codici) proprio qui. I punti corrispondenti saranno automaticamente accreditati sul suo conto punti. In seguito, puoi scegliere il premio che preferisci a tuo piacimento.",
    "app.register.via.code": "Registrazione con codice promozionale",
    "app.code.to.sign.up": "Inserisci il tuo codice promozionale qui per creare un account nel portale XY Promocode.",
    "app.code": "Promocode",
    "app.code.redeem": "Riscatta codice",
    "app.already.customer": "Sono già cliente",
    "app.ranking": "CLASSIFICA - LA MIA POSIZIONE",
    "app.belong.to.group": "Appartieni al gruppo",
    "app.rank": "Classifica",
    "app.name": "Nome",
    "app.rank.points": "Punti di classifica",
    "app.evaluation.criteria": "Criteri di classifica",
    "app.your.current.rank": "La tua attuale posizione in classifica",
    "app.show.rank": "Mostra classifica",
    "app.news.no.heading": "Nessuna notizia disponibile",
    "app.news.no.button": "Vai ai premi",
    "app.profile.language": "Linguaggio",

    // Salesmarathon
    "app.salesmarathon.goals": "Obiettivo",
    "app.salesmarathon.profit": "Bonus",
    "app.salesmarathon.lets.go": "Andiamo, raggiungi il tuo obiettivo!",
    "app.salesmarathon.currency1": "per",
    "app.salesmarathon.currency2": "di fatturato",

    // Salestriathlon
    "app.salestriathlon.month.heading": "I tuoi obiettivi mensili",
    "app.salestriathlon.goals.heading": "Il raggiungimento del tuo obiettivo",
    "app.salestriathlon.all.goals.heading": "Il tuo obiettivo generale della promozione",
    "app.salestriathlon.all.goals.text": "Se non raggiungi un obiettivo mensile, puoi comunque ottenere tutti i punti se raggiungi il tuo obiettivo complessivo (somma di tutti i mesi) al termine della promozione. A tal proposito: DAI TUTTO QUELLO CHE HAI!",
    "app.salestriathlon.finish.goals.heading": "Il raggiungimento del tuo obiettivo generale",
    "app.salestriathlon.your.reward": "Il tuo premio",
    "app.salestriathlon.first.month": "Primo mese",
    "app.salestriathlon.second.month": "Secondo mese",
    "app.salestriathlon.third.month": "Terzo mese",
    "app.salestriathlon.goals.contracts1": "Firma",
    "app.salestriathlon.goals.contracts2": "contratti!",
    "app.salestriathlon.goals.sales1": "Genera",
    "app.salestriathlon.goals.sales2": "Vendite",


    "page.404.headline": "OH NO...",
    "page.404.subline": "Si è verificato un problema.",
    "page.404.info": "O si è verificato un terribile errore o la pagina non esiste. Ma non preoccuparti, siamo qui per aiutarti!",
    "page.404.action.home": "Vai alla pagina iniziale",
    "page.404.action.contact": "Contattaci",
    "page.404.domain.headline": "Errore nella digitazione?",
    "page.404.domain.info": "Controlla l’URL che hai inserito. Dovrebbe riportare qualcosa del genere:",
  }
}

const t = (key) => {
  let lang = "de"
  if (window.currentLang && window.currentLang.length) {
    lang = window.currentLang
  }

  if (typeof t9n[lang] === 'undefined') return key;
  const s = t9n[lang][key];
  return (typeof s !== 'undefined')
    ? (s)
    : key
}

export default t;


export function countriesOptions() {
  return {
    'DE': t("global.countries.germany"),
    'BE': t("global.countries.belgium"),
    'DK': t("global.countries.denmark"),
    'FI': t("global.countries.finnland"),
    'FR': t("global.countries.france"),
    'GB': t("global.countries.uk"),
    'IT': t("global.countries.italy"),
    'LU': t("global.countries.luxembourg"),
    'NL': t("global.countries.netherlands"),
    'NO': t("global.countries.norway"),
    'AT': t("global.countries.austria"),
    'PL': t("global.countries.poland"),
    'RO': t("global.countries.romania"),
    'SE': t("global.countries.sweden"),
    'CH': t("global.countries.switzerland"),
    'CZ': t("global.countries.czech"),
    'HU': t("global.countries.hungary"),
    'US': t("global.countries.usa"),
  }
}

export function languagesOptions() {
  return {
    'de': t("language.profile.de"),
    'en': t("language.profile.en"),
    'fr': t("language.profile.fr"),
    'it': t("language.profile.it"),
  }
}

